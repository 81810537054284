.popupWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(196, 196, 196, 0.3);
  z-index: 9999;
  transition: .3s ease-in-out;
  animation: .5s popup-wrapper ease;

  .popup {
    background: #FFFFFF;
    border-radius: 5px;
    position: relative;
    padding: 45px;

    &Aside {
      position: fixed;
      top: 0;
      right: 0;
      width: 600px;
      height: 100vh;
      max-height: 100vh;
      background: #ffffff;
      z-index: 9000;
      display: flex;
      flex-direction: column;
      transition: .3s ease-in-out;
      animation: .5s aside-popup-show ease;
    }

    &Close {
      display: flex;
      position: absolute;
      top: 36px;
      right: 36px;
      cursor: pointer;
      transition: .3s ease-in-out;
      will-change: transform;

      &:hover {
        transform: scale(1.15);
      }
    }
  }
}

@keyframes popup-wrapper {
  from {
    background: initial
  }
  to {
    background: rgba(196, 196, 196, 0.3)
  }
}

@keyframes aside-popup-show {
  from {
    right: -612px
  }
  to {
    right: 0
  }
}
