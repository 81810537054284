.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 24px;
  bottom: 24px;
  right: 24px;
  padding: 24px;
  gap: 24px;
  border-radius: 8px;
  background: rgba(8, 9, 23, 0.70);
  backdrop-filter: blur(10px);
  color: #fff;
}

.info {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.rangeContainer {
  width: 100%;
  height: 8px;
  border-radius: 4px 0 0 4px;
  background: #2F3041;
  position: relative;
}

.containerLeft, .containerRight {
  height: 8px;
  background-color: inherit;
  position: absolute;
  z-index: 2;
  top: 0;
}

.containerLeft {
  left: 0;
}

.containerRight {
  right: 0;
}

.range {
  border-radius: 0;
  position: relative;
  top: -4px;
  margin: 0 auto;
  .trackStyle {
    height: 8px;
  }
  .handleStyle {
    width: 16px;
    height: 16px;
  }
}

.time {
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
  span {
    color: rgba(250, 250, 250, 0.70);
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.16px;
  }
}
