.task-no-time-in-work {
  background: #FFFFFF;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  &__header {
    padding: 11px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 55px;
    cursor: pointer;
    position: relative;
  }

  &__status {
    min-width: 40px;
    max-width: 40px;
    height: 33px;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    &:after {
      position: absolute;
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 100%;
      left: 14px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.default {
      &:after {
        background: #A5A7A8;
      }
    }

    &.error {
      &:after {
        background: #FE8282;
      }
    }

    &.complete {
      &:after {
        background: #6FCF97;
      }
    }

    &.in-work {
      &:after {
        background: #FFCF52;
      }
    }
  }

  &__title {
    padding: 0 48px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    flex: 1;
    max-width: 516px;
    min-width: 444px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__period {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    min-width: 250px;
    max-width: 250px;
  }

  &__performer {
    min-width: 250px;
    max-width: 250px;
  }

  &__list {
    background: #FAFAFA;
    border-top: 0.5px solid #EFEFEF;
    padding: 24px 16px 24px 56px;
  }
}
