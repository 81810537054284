.performers {

  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;

    &.black {
      color: #333333;
    }

    &.gray {
      color: #A5A7A8;
    }
  }

  &__items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &.line {
      > * {
        margin-bottom: 0;
      }
    }
  }

  &__item {
    user-select: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.16px;
    color: #333333;
    background: #EFEFEF;
    border-radius: 100%;
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    margin-right: 8px;
    margin-bottom: 4px;
    position: relative;

    &:hover {
      .performers__tooltip {
        opacity: 1;
      }
    }

    &:first-of-type {
      .performers__tooltip {
        transform: none;
        left: -20px;

        &:before {
          left: 37px;
        }
      }
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__name {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }
}
