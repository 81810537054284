.taskDinner {
  pointer-events: none;
  user-select: none;
  display: flex;
  height: 100%;
  align-items: flex-start;
  padding: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1963_36295)'%3E%3Cline x1='13.3536' y1='-0.646447' x2='-9.64645' y2='22.3536' stroke='%23EFEFEF'/%3E%3Cline x1='21.3536' y1='-0.646447' x2='-2.64645' y2='23.3536' stroke='%23EFEFEF'/%3E%3Cline x1='29.3536' y1='-0.646447' x2='4.35355' y2='24.3536' stroke='%23EFEFEF'/%3E%3Cline x1='37.3536' y1='-0.646447' x2='12.3536' y2='24.3536' stroke='%23EFEFEF'/%3E%3Cline x1='45.3536' y1='-0.646447' x2='20.3536' y2='24.3536' stroke='%23EFEFEF'/%3E%3Cline x1='5.35355' y1='-0.646447' x2='-17.6464' y2='22.3536' stroke='%23EFEFEF'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1963_36295'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.taskDinner__time {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #A5A7A8;
}

.taskDinner__title {
  margin-left: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #A5A7A8;
}
