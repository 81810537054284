.calendar {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;

  &__content {
    padding: 0 24px;
  }

  &__body {
    position: absolute;
    top: 0;
    left: calc(100% + 20px);
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 16px rgba(96, 96, 96, 0.08);
    transition: 0.3s ease .2s;
    z-index: 3;

    &_active {
      width: 350px;
      height: 350px;
      padding: 30px 31px 37px;
      transition: 0.3s ease;
    }

    &_active {
      &.calendar__header,
      &.calendar__table {
        opacity: 1;
        transform: scale(1);
        transition: 0.3s ease .2s;
      }
    }
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
  }

  &__header,
  &__table {
    opacity: 1;
    transform: scale(1);
    transition: 0.3s ease;
    font-size: 16px;
    line-height: 24px;
  }

  &__month {
    color: #333333;
    font-size: 16px;
    letter-spacing: 0.016px;
    line-height: 1.5;
  }

  &__nav {
    display: flex;
    align-items: center;
  }

  &__table {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -10px;
    margin-right: -10px;

    & > div {
      flex-basis: calc(100% / 7);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &::after {
      content: '';
      display: block;
      margin-left: auto;
    }
  }

  &__day-name {
    color: #a5a7a8;
    font-size: 12px;
    margin-bottom: 20px;
    height: 12px;
  }

  &__day {
    color: #333333;
    height: 40px;
    border-radius: 4px;
    transition: 0.3s ease;
    cursor: pointer;

    &_today {
      background: #2F80ED;
      color: #ffffff;
    }

    &_active {
      background: #F5F9FE;
      color: #2F80ED;
    }

    &_gray,
    &:hover:not(&_active):not(&_empty) {
      background: #F5F9FE;
      color: #2F80ED;
    }

    &_empty {
      pointer-events: none;
    }
  }
}
