.mapAside {
  position: relative;

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;
    position: relative;

    .search-input {
      margin: 0;
    }

    .search-input__icon-wrapper {
      border-radius: 5px 0 0 5px;
      padding: 0 12px;
    }

    .search-input__input {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #343940;
      border-radius: 0 5px 5px 0;

      &::placeholder {
        color: #A5A7A8;
      }
    }
  }

  &__filter {
    cursor: pointer;
    user-select: none;
    display: flex;
    padding: 11px;
    border: 1px solid #2F80ED;
    border-radius: 5px;
    position: relative;
  }

  &__filterCount {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    background: #2F80ED;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    color: white;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    top: 9px;
    right: 8px;
    will-change: transform;
  }

  &__asideScroll {
    overflow-y: auto;
    height: calc(100% - 92px);
    padding-bottom: 32px;

    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
  }

  &__content {
    background: gainsboro;
    width: calc(100% - 500px);
    height: 100%;
  }

  &__block {
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__blockHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__blockTitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      display: flex;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      left: -24px;
      top: 8px;
    }
  }

  &__blockCount {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #A5A7A8;
  }

  &__blockContent {
    .mapUser {
      border-bottom: 1px solid #EFEFEF;
    }
  }

  &__info {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: white;
  }

  &__infoHeader {
    width: calc(100% + 96px);
    display: flex;
    align-items: center;
    background-color: white;
    padding: 16px 48px;
    border-bottom: 1px solid #EFEFEF;
    margin-bottom: 8px;
    cursor: pointer;
    position: absolute;
    left: -48px;
    top: 0;
    right: 0;
    z-index: 10;
  }

  &__infoBack {

    .new-button__text {
      color: #000000 !important;
      font-size: 20px !important;
      line-height: 28px !important;
    }
  }

  &__infoContent {
    padding-top: 98px;
    height: 100%;
    overflow: auto;
    padding-bottom: 48px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none;
    }
  }

  &__infoContentBlock {
    margin-bottom: 16px;

    > p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #333333;
      margin-bottom: 8px;
    }

    > span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #A5A7A8;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__blockLogs {
    padding: 8px;

    &:last-of-type {
      .mapAside__blockLogsItem {

        &:last-of-type {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  &__blockLogsItem {
    position: relative;
    display: flex;
    column-gap: 8px;
    padding-left: 50px;
    margin-bottom: 8px;

    &:after {
      position: absolute;
      content: '';
      top: 20px;
      left: 60px;
      width: 4px;
      height: 100%;
      background: #EFEFEF;
      z-index: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__blockLogsTime {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    border-radius: 12px;
    padding: 4px 8px;
    cursor: pointer;
    position: absolute;
    top: -2px;
    left: -4px;
    transition: all 0.3s ease;
    &:hover {
      background-color: $light-grey;
    }
  }

  &__blockLogsIcon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 100%;
    background-size: 24px 24px;
    background-color: #EFEFEF;
    background-position: 50% 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
  }

  &__blockLogsText {
    line-height: 20px;
    color: $black;
    &_geoDeviation, &_isExpired {
      color: $salmon;
    }
    &_geoDeviation {
      margin-left: 4px;
    }
    &_taskTitle {
      color: $ocean200;
    }
  }

  &__blockLogsZone, &__blockLogsComment {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: $txt200;
    margin-top: 4px;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }

  &__blockLogsComment {
    svg {
      font-style: italic;
    }
  }

  &__blockLogsImages {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }
}
