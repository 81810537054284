.new-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__check {
    width: 24px;
    height: 24px;
    padding: 3px;
    margin-right: 6px;
    position: relative;
    display: block;

    &::before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      flex-basis: 19px;
      border-radius: 2px;
      border: 1px solid #AAACAD;
      box-sizing: border-box;
    }

    svg {
      opacity: 0;
      transition: 0.3s;
      position: absolute;
      top: 3px;
      left: 3px;
      width: 18px;
      height: 18px;
    }
  }

  &__text {
    height: 18px;
    line-height: 18px;
    vertical-align: middle;
    display: inline-block;
  }

  &__input {
    display: none;
  }

  &__input:checked + & {
    &__check::before {
      border-color: #2f80ed;
    }

    &__check svg {
      opacity: 1;
    }
  }

  &.disabled {
    pointer-events: none;

    .checkbox__check {
      &::before {
        background: #DDDDDD;
        border: none;
      }

      svg {
        path {
          fill: #B0B2B3;
        }
      }
    }
  }
}
