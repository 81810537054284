.statisticsPage {
  width: 100%;

  &__block {
    margin-bottom: 48px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__blockTitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 32px;
  }

  &__blockContent {
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__title {
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  }

  &__chart {
    margin-bottom: 24px;
  }

  &__legends {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  &__chartWrapper {
    display: flex;
    align-items: flex-start;
  }

  &__chartDoughnut {
    width: 300px;
    min-width: 300px;
    margin-right: 48px;

    .chartDoughnut__doughnut {
      width: 200px;
      height: 200px;
      padding: 0;
    }

    .chartDoughnut__total {
      font-size: 21px;
    }

    .chartDoughnut__legend {

      .legends__itemTitle {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &__chartBar {
    width: 100%;
  }
}
