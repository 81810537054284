.users-add {
  position: absolute;
  z-index: 1;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 45px;

  &__header {
    margin-bottom: 48px;
  }

  &__title {
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 48px;
  }

  &__body {
    height: calc(100vh - 90px);
    margin-left: -45px;
    width: calc(100% + 90px);
    overflow: scroll;
    padding-right: 45px;
    padding-left: 45px;
    padding-bottom: 145px;

    .alphabet {
      margin-top: 48px;
    }
  }
}
