.statisticTable {
  width: 100%;

  &__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EFEFEF;
    color: #A5A7A8;
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 1;

    .statisticTable__user {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    .statisticTable__data {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__user {
    width: 627px;
    min-width: 627px;
    padding: 10px 8px;
    position: sticky;
    left: 0;
    background: #ffffff;
  }

  &__data {
    width: max-content;
    min-width: 300px;
    padding: 10px 8px;
    white-space: nowrap;
    flex: 1;
  }

  &__wrapper {
    height: 260px;
    overflow: auto;
  }

  &__inner {
    width: max-content;
    position: relative;
  }

  &__aside {
    position: absolute;
    left: 0;
    top: 2px;
    width: 627px;
    background: #FFFFFF;
  }

  &__asideHeader {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #A5A7A8;
  }

  &__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EFEFEF;
  }
}
