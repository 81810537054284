.popup-smena {
  background: #FFFFFF;
  box-shadow: 0 0 16px rgba(96, 96, 96, 0.25);
  border-radius: 5px;
  position: absolute;
  top: 100px;
  right: 200px;
  min-width: 350px;
  padding: 27px 24px 24px 24px;

  &__close {
    display: flex;
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
  }

  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 12px;
  }

  &__desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: #333333;
    margin-bottom: 24px;
  }
}
