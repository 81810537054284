.task-track {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% - 18px);
  min-height: 20px;
  padding: 0 17px;
  border-radius: 4px;
  background: #EFEFEF;
  margin-left: 9px;
  margin-right: 9px;
  cursor: default;
  z-index: 1;

  &.check-track {
    background: #FAFAFA;
    border-radius: 0;
    margin: 0 1px;
    width: calc(100% - 3px);
    align-items: center;
    justify-content: center;

    &::before {
      display: none;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 4px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #B0B2B3;
    border-radius: 4px 0 0 4px;
  }

  &__time {
    display: block;
    margin-right: 13px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
  }

  &__title {
    display: block;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
