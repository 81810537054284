.pinContainer {
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  top: -16px;
  left: -16px;
  transition: all 0.3s ease;
  .pinDescription {
    opacity: 0;
    visibility: hidden;
    padding: 4px;
    background-color: #fff;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.16px;
    transform: translateX(-50%);
    position: absolute;
    bottom: -24px;
    left: 24px;
    transition: all 0.3s ease;
  }
  &.active {
    width: 48px;
    height: 48px;
    top: -24px;
    left: -24px;
    .pinDescription {
      opacity: 1;
      visibility: visible;
    }
  }
}
