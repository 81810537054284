.block-form {
  max-width: 444px;

  &__title {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #333333;
    margin-bottom: 24px;
  }

  &__desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #A5A7A8;
    margin-bottom: 16px;
  }

  &__date {
    display: flex;
    align-content: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #333333;
    margin-bottom: 16px;
  }

  &__button-inner {
    display: flex;
    gap: 8px;
  }
}
