.tabs {
  display: flex;
  align-items: center;
  width: 100%;

  &__tab {
    cursor: pointer;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #A5A7A8;
    margin-right: 24px;
    position: relative;
    transition: .3s ease-in-out;
    padding: 2px 2px;

    &:after {
      content: '';
      position: absolute;
      height: 2px;
      right: 0;
      left: initial;
      bottom: 0;
      width: 0;
      background: #2F80ED;
      border-radius: 1px;
      transition: .3s ease-in-out;
      will-change: transform;
    }

    &:hover {
      color: #393B55;
    }

    &.active {
      pointer-events: none;
      color: #333333;

      &:after {
        width: 100%;
        right: initial;
        left: 0;
      }
    }
  }
}
