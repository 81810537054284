.task-assessment {
  &__heading {
    width: 100%;
    margin-top: 48px;
    font-size: 20px;
    margin-bottom: 48px;
  }

  &__user {
    width: 100%;

    .user-assessment {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .user-info {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      .user-position {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #A5A7A8;
      }
    }

    .user-comment {
      width: 100%;
      margin-top: 24px;
      background: #FAFAFA;
      border-radius: 4px;
      padding: 12px 16px;
      font-size: 16px;
      line-height: 24px;
    }

    .user-buttons {
      display: flex;

      & > div {
        width: 100px;
        height: 40px;
        border-radius: 5px;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.16px;
        flex-shrink: 0;

        &:not(:last-child) {
          margin-right: 13px;
        }
      }

      .button_accepted {
        background-color: #F5F9FE;
        color: #2F80ED;
      }

      .button_rejected {
        background-color: #FE8282;
        color: #FFFFFF;
      }

      .button_accept {
        border: 1px solid #2F80ED;
        color: #2F80ED;
        cursor: pointer;

        &-active {
          background-color: #2F80ED;
          color: white;
        }
      }

      .button_reject {
        border: 1px solid #FE8282;
        color: #FE8282;
        cursor: pointer;

        &-active {
          background-color: #FE8282;
          color: white;
        }
      }
    }
  }

  .task-assessment__comment {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .task-assessment__reset {
    margin-top: 24px;
    width: max-content;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #2F80ED;
    cursor: pointer;
    padding: 6px 4px;
  }

  .new-button {
    margin-top: 48px;
    margin-left: auto;
  }

  .task-assessment__await {
    width: 100%;
    text-align: left;
    margin-top: 20px;
  }
}
