.new-textarea {
  width: 100%;
  position: relative;

  &__label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 4px;
    display: block;
    color: #333333;
  }

  &__container {
    display: flex;
    align-items: center;
    border-radius: 5px;

    &.errorBorder {
      outline: 1px solid #FE8282;
    }
  }

  &__textarea {
    height: 172px;
    max-height: 172px;
    min-height: 172px;
    max-width: 100%;
    min-width: 100%;
    border-radius: 4px;
    letter-spacing: 0.16px;
    font-family: sans-serif;
    resize: none;
    border: 1px solid transparent;
    display: block;
    width: 100%;
    padding: 11px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
    background: #FAFAFA;
    color: #333333;
    transition: 0.3s ease;

    &::placeholder {
      color: #A5A7A8;
    }

    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }

    &:active, &:focus {
      background: #F5F9FE;
      color: #2f80ed;
    }
  }

  &__error {
    color: #FE8282;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.16px;
    margin-top: 8px;
  }
}
