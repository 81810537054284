.mapFilter {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background: #FFFFFF;
  box-shadow: 0 4px 16px rgba(96, 96, 96, 0.25);
  border-radius: 5px;
  padding: 24px;
  width: 327px;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: .3s ease-in-out;
  transform: translateY(8px);
  will-change: transform;

  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 24px;
  }

  .new-select {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.active {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }
}
