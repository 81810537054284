.chartDoughnut {

  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  &__doughnut {
    position: relative;
    width: 176px;
    height: 128px;
    padding: 0 24px;
  }

  &__total {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__legend {

    .legends__item {
      margin-bottom: 6px;
      margin-right: 0;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__legendItem {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
