.list-table {
  display: block;
  width: 100%;
  overflow: auto;
  &__row {
    display: flex;
    width: 100%;
    align-items: stretch;
    &_header {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.16px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #A5A7A8;
      .list-table__col {
        display: flex;
        padding: 15px 8px;
      }
    }

    .ant-picker-range {
      border: none;
      box-shadow: none;
      margin-bottom: 0;
      height: 30px;
      background: transparent;
      padding: 8px;

      .ant-picker-suffix {
        color: rgba(0, 0, 0, 1);
      }

      .ant-picker-input {
        display: block;
      }

      input {
        text-align: center;
      }
    }
  }
  &__edit-btn {
    display: block;
    opacity: 0;
    padding: 6px;
  }
  &__col {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-bottom: 1px solid #EFEFEF;
    padding: 3px 7px;
    min-height: 56px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-shrink: 0;
    &_avatar {
      min-width: 101px;
      max-width: 101px;
      display: flex;
      justify-content: center;
    }
    &_name {
      justify-content: space-between;
      min-width: 400px;
      max-width: 400px;

      &:hover {
        .list-table__edit-btn {
          opacity: 1;
        }
      }
    }
    &_role {
      min-width: 254px;
      max-width: 254px;
    }
    &_specialisation {
      min-width: 340px;
      max-width: 340px;
    }
    &_status {
      min-width: 130px;
      max-width: 130px;
    }
    &_place {
      min-width: 213px;
      max-width: 213px;
    }
    &_zone_name {
      min-width: 125px;
      max-width: 125px;
    }
    &_number {
      display: flex;
      justify-content: center;
      min-width: 175px;
      max-width: 175px;
    }
    &_edit {
      min-width: 30px;
      max-width: 30px;
    }
    &_category {
      min-width: 100px;
      max-width: 100px;
    }
    &_real_start_time {
      min-width: 120px;
      max-width: 120px;
      position: relative;
    }
    &_real_end_time {
      min-width: 120px;
      max-width: 120px;
    }
    &_time {
      min-width: 100px;
      max-width: 100px;
    }
    &_geo_status {
      min-width: 120px;
      max-width: 120px;
    }
    &_status_geo {
      min-width: 130px;
      max-width: 130px;
    }
    .list-table__user-avatar {
      cursor: default;
    }
  }

  .no-results {
    margin: 52px 0;
  }
}

.list-table__row_header {
  position: relative;

  .list-table__col {
    justify-content: flex-start;
    > span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .sort-arrows {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    padding: 3px 6px;
    margin-left: 8px;
    cursor: pointer;

    .active-sort {
      path {
        fill: #2F80ED;
      }
    }
  }

  &_filters {
    position: relative;
    gap: 5px;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .button_filter {
      width: 100%;
      height: 48px;
      background: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #EFEFEF;
      border-radius: 5px;
      cursor: pointer;
      margin-right: 10px;
    }

    .button_date {
      display: flex;
      align-items: center;
      flex: 1 0 300px;
      height: 48px;
      padding: 0 16px;
      background: #FFFFFF;
      border: 1px solid #EFEFEF;
      border-radius: 5px;

      .ant-picker-range-separator {
        span {
          color: #2F80ED;
        }

      }

      .ant-picker-input {


        input {
          color: #2F80ED;

        }
      }

      &.active {
        background: #F5F9FE;
        color: #2F80ED;
      }
    }

    .list-users-table_col {
      min-width: auto;
      height: 48px;
      min-height: 48px;
      padding: 14.5px 16px;
      border: 1px solid #EFEFEF;
      border-radius: 5px;

      .active-filters {
        display: flex;
        align-items: center;
        column-gap: 4px;

        &_span {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #A5A7A8;

          &-active {
            color: #333333;
            font-size: 16px;
            line-height: 19px;
          }
        }

        &__filter {
          &-name {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #2F80ED;
          }

          &:not(:last-child)::after {
            content: ', ';
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #2F80ED;

          }

          &-counter {
            display: inline-flex;
            justify-content: center;
            width: 25px;
            height: 18px;
            padding: 2px 4px;
            background: rgba(47, 128, 237, 0.1);
            border-radius: 27px;
            color: #2F80ED;
            font-size: 12px;
            line-height: 14px;
          }
        }
      }

      span {
        width: max-content;
        display: inline-block;
        cursor: pointer;
        color: #333333;
      }

      svg {
        margin-left: 12px;

        path {
          stroke: #B0B2B3;
        }
      }

    }

    .filter-popup {
      width: 377px;
      height: 309px;
      background: #FFFFFF;
      box-shadow: 0 0 16px rgba(96, 96, 96, 0.25);
      border-radius: 5px;
      overflow: hidden;
      padding: 30px;
      color: #333333;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      position: absolute;
      z-index: 2;
      top: 0;

      &.open {
        pointer-events: all;
        opacity: 1;
      }

      &__close {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
      }

      &__name {
        font-size: 20px;
      }

      &__reset,
      &__choose-all {
        color: #2F80ED;
        font-size: 16px;
        margin: 25px 0 5px;
        cursor: pointer;
      }

      &__content {
        height: calc(100% - 55px);
        overflow-y: auto;

        .rf-content__line {
          margin-top: 15px;
          display: flex;
          align-items: center;

          input {
            width: 18px;
            height: 18px;
          }

          span {
            font-size: 16px;
            margin-left: 15px;
            max-width: 87%;
          }
        }
      }

      &__deleted {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $salmon;
        margin-left: auto;
        margin-right: 5px;
      }
    }
  }
}

