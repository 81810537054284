.add-person-popup {
  &__added {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    gap: 10px;
  }

  &__added-person {
    background: #F5F9FE;
    border-radius: 30px;
    padding: 4px 12px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    &-name {
      display: block;
      color: #2F80ED;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
    }

    &-delete {
      margin-left: 10px;
      display: flex;
      transition: all 100ms cubic-bezier(.31, .01, .48, 1.47);

      &:active {
        transform: scale(0.95);
      }
    }
  }
}

.alphabet {
  &__none {
    user-select: none;
    font-size: 16px;
    line-height: 20px;
    color: #a5a7a8;
  }

  &__title {
    font-size: 24px;
    line-height: 28px;
    color: #333333;
    font-weight: 400;
    margin-top: 24px;
  }
}

.alphabet-person {
  line-height: 32px;
  font-size: 14px;
  color: #333333;
  transition: 0.3s ease;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 0;
  cursor: pointer;
  box-shadow: -44px 0 0 0 transparent, 60px 0 0 0 transparent;
  position: relative;

  &_active {
    color: #2F80ED;
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 2px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__name {
    font-size: 16px;
    line-height: 24px;
  }

  &__specialisation {
    font-size: 12px;
    line-height: 20px;
    color: #a5a7a8;
    margin-right: 4px;
    white-space: nowrap;
    width: max-content;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__add-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #2F80ED;
    transition: 0.3s ease;
    opacity: 0;
  }

  &:hover {
    background: #F5F9FE;
    box-shadow: -44px 0 0 0 #F5F9FE, 60px 0 0 0 #F5F9FE;

    .alphabet-person__add-btn {
      opacity: 1;
    }
  }
}
