.new-input {
  width: 100%;
  position: relative;

  &__label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 4px;
    display: block;
    color: #333333;
  }

  &__container {
    display: flex;
    align-items: center;
    border-radius: 5px;

    &.withPrefix {
      padding-left: 15px;
      background-color: #FAFAFA;
      color: #333333;

      .new-input__input {
        width: 100%;
        padding-left: 0 !important;
      }
    }

    &.errorBorder {
      outline: 1px solid #FE8282;
    }

    &.prefixFocus {
      background: #F5F9FE;
      color: #2f80ed;
    }

    &.inputTypePassword {
      .new-input__visiblePassword {
        width: 48px;
        min-width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FAFAFA;
        cursor: pointer;
      }
    }
  }

  &__prefix {

  }

  &__visiblePassword {

  }

  &__input {
    border: 1px solid transparent;
    border-radius: 4px;
    display: block;
    width: 100%;
    padding: 11px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
    background: #FAFAFA;
    color: #333333;
    transition: 0.3s ease;

    &::placeholder {
      color: #A5A7A8;
    }

    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }

    &:active {
      background: #F5F9FE;
      color: #2f80ed;
    }
  }

  &__error {
    color: #FE8282;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.16px;
    margin-top: 8px;
  }
}
