.route-zones {
  &_edit {
    .route-zones__zone {
      &:nth-last-child(2) {
        &::after {
          height: 20px;
        }
      }

      &:last-child {
        &::after {
          height: 0;
        }
      }
    }
  }

  &__mark {
    position: absolute;
    display: block;
    background: black;
    width: 7px;
    height: 7px;
    transform: translate (-18px);
    left: 3px;

    &.approved {
      background: #2F80ED;
    }

    &.checkpoint {
      width: 5px;
      height: 5px;
      transform: translate (-17px);
      background: #2F80ED;
    }
  }

  &__zone {
    padding: 8px 15px 7px 20px;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
    margin: 0 0 0 -20px;

    &--delete {
      color: $salmon;
    }


    span {
      color: $salmon;
      cursor: pointer;
      transform: translateY (-2px);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
    }

    &:hover {
      background-color: rgba(47, 128, 237, .1);

      span {
        opacity: 1;
        visibility: visible;
      }
    }

    &::before {
      content: '';
      width: 11px;
      height: 11px;
      border: 1px solid #2F80ED;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -5px;
    }

    &::after {
      content: '';
      border-left: 1px dashed #2F80ED;
      height: 20px;
      position: absolute;
      left: 5px;
      top: 22px;
    }

    &:nth-last-child(2) {
      &::after {
        height: 54px;
      }
    }
  }

  &__route {
    padding: 0 0 0 20px;
    margin-bottom: 24px;

    &.empty {
      padding: 0;
    }
  }
}
