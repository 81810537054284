.task-no-time-inside {

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    position: relative;
  }

  &__status {
    position: absolute;
    left: -25px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background: #B0B2B3;
    border-radius: 100%;

    &.default {
      background: #B0B2B3;
    }

    &.working {
      background: #FFCF52;
    }

    &.complete {
      background: #6FCF97;
    }

    &.error {
      background: #FE8282;
    }

    &.pause {
      background: #FA9131;
    }

    &.expects {
      background: #968DFF;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }

  &__view {
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2F80ED;
  }

  &__block {
    margin-bottom: 10px;
    display: flex;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__item {
    margin-right: 16px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__item-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A5A7A8;
  }

  &__item-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }

  &__start {

  }

  &__start-mobile {
    background: #FFF3F3;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FE8282;
    padding: 8px;
  }

  &__buttons {
    display: flex;
    align-items: center;

    .button {
      width: max-content;
      margin-right: 8px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
