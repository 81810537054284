.taskPopup {

  .tabs-popup {
    margin-bottom: 48px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin-top: 48px;
  }

  &__block {
    margin-bottom: 48px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .new-checkbox {
      &:last-of-type {
        margin-top: 24px;
      }
      &:first-of-type {
        margin-top: 0;
      }
    }

    > .new-button {
      margin-left: auto;
    }
  }

  &__times {
    display: flex;
    gap: 24px;
  }

  &__timesCheckbox {
    margin-top: 24px !important;
  }

  .textarea-wrapper {
    margin-bottom: 16px;
  }
}
