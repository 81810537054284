@import 'reset';
@import 'definitions';
@import 'global';
@import 'layout-screen';
@import '_ui';

// components
@import 'components/password-popup';
@import 'components/generate-password-popup';
@import 'components/create-schedule-popup';
@import 'components/reports-filters-popup';
@import 'components/history-page';
@import 'components/notification';
@import 'components/new-button';
@import 'components/pagination';
@import 'components/list-table';
@import 'components/tabs';
@import 'components/legends';
@import 'components/tasksStatistic';
@import 'components/chartLine';
@import 'components/chartDoughnut';
@import 'components/chartTasks';
@import 'components/chartBar';
@import 'components/aside';
@import 'components/asideNav';
@import 'components/alert';
@import 'components/new-select';
@import 'components/workingShiftsTypes';
@import 'components/priorityIcon';
@import 'components/calendar';
@import 'components/templates';
@import 'components/template';
@import 'components/applications';
@import 'components/application';
@import 'components/task-carousel';
@import 'components/block-form';
@import 'components/user-menu';
@import 'components/popup';
@import 'components/dialog-popup';
@import 'components/popup-screen';
@import 'components/search-input';
@import 'components/login';
@import 'components/schedule-table';
@import 'components/workshift-info';
@import 'components/extra-smena';
@import 'components/statisticsPage';
@import 'components/statisticTable';
@import 'components/history';
@import 'components/aside-popup';
@import 'components/approve';
@import 'components/ant-picker';
@import 'components/ant-modal';
@import 'components/ant-tooltip';
@import 'components/add-person-popup';
@import 'components/shift-edit-form';
@import 'components/users-add';
@import 'components/route-zones';
@import 'components/tabs-popup';
@import 'components/task-start';
@import 'components/time-inputs';
@import 'components/task';
@import 'components/task-track';
@import 'components/popup-smena';
@import 'components/label-status';
@import 'components/viewTaskPopup';
@import 'components/workRequestPopup';
@import 'components/files';
@import 'components/task-info';
@import 'components/taskPopup';
@import 'components/taskDinner';
@import 'components/task-list';
@import 'components/task-title';
@import 'components/toast';
@import 'components/generate-password-popup';
@import 'components/create-schedule-popup';
@import 'components/task-assessment';
@import 'components/task-message';
@import 'components/new-input';
@import 'components/new-checkbox';
@import 'components/new-textarea';
@import 'components/contentPriority';
@import 'components/contentStatus';
@import 'components/currentTimeLine';

// components tasks-no-time
@import 'components/tasks-no-time';
@import 'components/tasks-no-time-filter';
@import 'components/task-no-time';
@import 'components/performers';
@import 'components/task-no-time-in-work';
@import 'components/task-no-time-in-work-info';
@import 'components/task-no-time-inside';

// components map
@import 'components/mapAside';
@import 'components/mapUser';
@import 'components/mapFilter';
@import 'components/mapContainer';
@import 'components/mapPlacemark';

// import libs
@import '~antd/dist/antd.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '~swiper/swiper-bundle.min.css';
