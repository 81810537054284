.approve {
  margin-top: 45px;
  display: flex;
  flex-direction: column;

  .clear {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    button {
      color: $txt200;
      text-decoration: underline;
      font-size: 14px;
      line-height: 20px;
    }
  }

  h3 {
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 5px;
  }

  .block {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .buttons {
      display: flex;

      button {
        border: 1px solid transparent;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 40px;
        letter-spacing: 0.16px;
        background: #FAFAFA;
        color: #A5A7A8;
        transition: 0.3s ease;
        font-size: 14px;
        line-height: 20px;

        &:not(:last-child) {
          margin-right: 10px;
        }

        &.selected {
          background: #F5F9FE;
          color: #2F80ED;
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
    }

    .profession {
      color: $txt200;
      font-size: 14px;
      line-height: 20px;
    }

    .comment-button {
      text-decoration: underline;
      color: $sky;
      font-size: 14px;
      line-height: 20px;
    }

    .status {
      border-radius: 5px;
      padding: 10px 22px;
      font-size: 14px;
      line-height: 20px;
      width: auto !important;
      height: auto !important;

      &.outlined {
        border: 1px solid $sky;
        background: #ffffff !important;

        &.status__approved {
          border-color: $sky;
          color: $sky !important;
        }

        &.status__error {
          border-color: $salmon;
          color: $salmon !important;
        }

        &.selected {
          &.status__approved {
            border-color: $sky;
            color: $pure-white !important;
            background-color: $sky !important;
          }

          &.status__error {
            border-color: $salmon;
            color: $pure-white !important;
            background-color: $salmon !important;
          }
        }
      }

      &:not(:last-of-type) {
        margin-right: 13px;
      }
    }
  }
}
