.task {
  display: flex;
  align-items: flex-start;
  min-height: 20px;
  padding: 10px 17px;
  border-radius: 4px;
  position: relative;
  background: #FAFAFA;
  outline: 1px solid rgba(0, 0, 0, 0.05);
  margin: 2px 4px;
  z-index: 1;
  cursor: pointer;
  width: calc(100% - 8px);

  //&:hover {
  //  z-index: 6;
  //}

  &_task-info-active {
    z-index: 999;
  }

  &_absolute {
    position: absolute;
  }

  &_small {
    padding: 3px 17px;
  }

  &_status__default {
    &.task-min-small {
      background-color: #EFEFEF !important;

      &::before {
        background: #B0B2B3 !important;
      }
    }
  }

  &-min-small {
    min-height: 18px;
    padding: 5px 17px;

    .task__unfixedIcon {
      right: 8px;
      bottom: 0;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 4px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #EFEFEF;
    border-radius: 4px 0 0 4px;
  }

  &_status__progress {
    &::before {
      background: $lemon;
    }
  }

  &_status__error {
    &::before {
      background: $salmon;
    }
  }

  &_status__success {
    &::before {
      background: $grass;
    }
  }

  &_status__default {
    &::before {
      background: $bg200;
    }
  }

  &_yellow {
    background: #FFFAEE;

    &::before {
      background: #FFCF52;
    }
  }

  &_red {
    background: #FFF3F3 !important;

    &::before {
      background: #FE8282;
    }
  }

  &_green {
    background: #F1FAF5;

    &::before {
      background: #6FCF97;
    }
  }

  &__time {
    display: flex;
    min-width: 65px;
    margin-right: 8px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;

    &--very-small {
      transform: translateY(-2px);
    }
  }

  &__description-block {
    display: flex;
    width: 100%;
  }

  &__description {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__name {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    gap: 4px;
    padding-right: 8px;

    &--very-small {
      transform: translateY(-3px);
    }

    &.priority1,
    &.priority2 {
      display: flex;
      align-items: center;

      > span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.priority1 {
      color: #FA9131;
    }

    &.priority2 {
      color: #FE8282;
    }
  }

  &__unfixedIcon {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 8px;
    bottom: 8px;
  }

  .priorityIcon {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }

  &--priority {
    z-index: 2;
  }
}
