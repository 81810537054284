.setPasswordPopup {
  width: 600px;

  &__block {
    margin-bottom: 24px;

    .spoiler {
      margin-top: 12px;

      .spoiler__header {
        color: #2F80ED;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        border-bottom: none;
        margin-bottom: 0;
        justify-content: flex-start;
        padding: 0;

        svg.spoiler__arrow {
          margin-left: 8px;
        }
      }

      .spoiler_active {
        .spoiler__body {
          padding-top: 12px;
        }
      }

      .spoiler__body {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #A5A7A8;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .passwordPopupHeader {
    padding: 6px 0;
    font-size: 24px;
    margin-bottom: 45px;
  }

  .passwordPopupDesc {
    color: #FE8282;
    margin-bottom: 30px;
    line-height: 19px;
  }

  .input-block {
    margin-bottom: 24px;
  }

  .input-block__title {
    font-size: 14px !important;
    line-height: 24px;
  }

  .input-block__password-wrapper .input {
    width: 100%;

    &::placeholder {
      font-size: 14px;
      color: #A5A7A8;
      letter-spacing: 0.16px;
    }
  }

  .passwordRequirements {
    margin-top: 8px;
    ul {
      li {
        color: #A5A7A8;
        font-size: 12px;
        line-height: 16px;
        position: relative;

        &::before {
          content: '●';
          display: inline-block;
          text-align: center;
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }

        &:not(:first-child) {
          margin-top: 6px;
        }

        &.correct, &.uncorrect {
          display: flex;
          align-items: center;
        }

        &.correct::before, &.uncorrect::before {
          content: '';
        }

        &.correct {
          color: #6FCF97;

          &::before {
            background-image: url('../../assets/check-green.svg');
          }
        }

        &.uncorrect {
          color: #FE8282;

          &::before {
            background-image: url('../../assets/close-red.svg');
          }
        }
      }
    }
  }

  .buttonLine {
    display: flex;
    justify-content: flex-end;
    margin-top: 48px;

    .aside-popup__header-button_log-out {
      flex-grow: 1;
      justify-content: flex-start;
    }

    .button {
      width: 137px;
      flex-shrink: 0;

      &.disabled {
        pointer-events: none;
        background-color: $txt200;
      }
    }
  }
}
