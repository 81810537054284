.ant-select-dropdown {
  z-index: 100000 !important;
}

.time-inputs {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  column-gap: 8px;
  width: calc((100% - 24px) / 2);

  &__item {
    position: relative;
    width: calc((100% - 8px) / 2);

    .ant-select {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: #FAFAFA !important;
      border-radius: 5px;
    }
  }

  .ant-select {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f9fe !important;
    border-radius: 5px;

    .ant-select-selector {
      height: auto;
      padding: 14px 16px;
    }
  }

  .ant-picker {
    margin-bottom: 0;
    height: auto;
    padding: 3px 10px;
  }

  .timeEnd {
    pointer-events: none;
  }

  &:last-of-type {
    margin-right: 0;
  }
}
