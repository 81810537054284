.ant-modal-mask {
  z-index: 9900;
}

.ant-modal-wrap {
  z-index: 9999;
}

.ant-modal {
  width: 100% !important;
  max-width: none;
  padding: 0;

  .ant-modal-close {
    border-radius: 100%;
    background: #323639;
    right: 40px;

    &-x {
      svg {
        fill: white
      }
    }
  }

  .ant-modal-content {
    background: transparent;
    box-shadow: none;

    .ant-modal-body {
      padding: 0;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .ant-modal-footer {
      display: none
    }
  }
}
