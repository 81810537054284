.search-input {
  display: flex;
  align-items: stretch;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 37px;
  position: relative;

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    padding-left: 16px;
    border-radius: 4px 0 0 4px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }

  &__input {
    padding: 9px 16px 9px 56px;
    border-radius: 0 4px 4px 0;
    width: 100%;
  }
}
