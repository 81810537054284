.reports-filters-popup {
  h2 {
    font-size: 24px;
  }

  .rf-content {
    &__all {
      font-size: 16px;
      letter-spacing: 0.16px;
      color: #2F80ED;
      margin: 0 0 5px;
      cursor: pointer;
    }

    &__line {
      margin-top: 25px;
      display: flex;
      align-items: center;

      input {
        width: 18px;
        height: 18px;
      }

      span {
        font-size: 16px;
        margin-left: 15px;
      }
    }

    &__button {
      width: 151px;
      height: 48px;
      background: #2F80ED;
      border-radius: 5px;
      font-weight: bold;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      text-transform: uppercase;
      margin-top: 55px;
      float: right;
    }
  }
}
