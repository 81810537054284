.aside {
  position: fixed;
  top: 0;
  left: 0;
  width: 120px;
  height: 100vh;
  background: $accent;
  color: #ffffff;
  display: block;
  padding: 24px 0;
  z-index: 2;
}
