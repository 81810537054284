.priorityIcon {
  width: 24px;
  height: 24px;
  background-size: cover;
  background-position: 50% 50%;

  &--0 {
    background-image: url("../../assets/icon/priority0.svg");
  }
  &--1 {
    background-image: url("../../assets/icon/priority1.svg");
  }
  &--2 {
    background-image: url("../../assets/icon/priority2.svg");
  }
}
