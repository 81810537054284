.mapContainer {
  width: calc(100% - 500px);
  height: 100%;

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: '';
      display: block;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      border: 3px solid;
      border-color: #020850 transparent;
      animation: rotation 1s linear infinite;
    }
  }

  &__zoom {
    position: absolute;
    right: 24px;
    bottom: 48px;
    z-index: 0;

    &_infoOpen {
      bottom: 174px;
    }

    > div {
      width: 40px;
      height: 40px;
      background: white;
      padding: 8px;
      margin-bottom: 1px;
      cursor: pointer;

      &:first-of-type {
        border-radius: 8px 8px 0 0;
      }

      &:last-of-type {
        margin-bottom: 0;
        border-radius: 0 0 8px 8px;
      }
    }
  }

  &__department {
    position: absolute;
    z-index: 0;
    background: #080917B2;
    padding: 4px 8px;
    gap: 8px;
    border-radius: 8px;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;

    > svg {
      width: 24px;
      height: 24px;
    }
  }

  &__controls {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 24px;
    bottom: 24px;
    right: 24px;
    padding: 24px;
    gap: 24px;
    border-radius: 8px;
    background: rgba(8, 9, 23, 0.70);
    backdrop-filter: blur(10px);
    color: #fff;
  }

  &__controlsLine {
    width: 100%;
    display: flex;
    gap: 1px;
  }

  &__controlsLineItem {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1px;
    cursor: pointer;

    > div {
      position: relative;
      width: 100%;
      border-top: thin solid rgba(255, 255, 255, 0.05);
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 24px;
      overflow: hidden;
      color: rgba(250, 250, 250, 0.70);
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 10px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.16px;

      &:before {
        position: absolute;
        content: '';
        width: 1px;
        height: 4px;
        background: rgba(255, 255, 255, 0.05);
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &:before {
      content: '';
      background: #0F58BA;
      height: 24px;
      width: 100%;
      border-radius: 1px;
    }

    &.active {
      &:before {
        background: #2F80ED;
      }
    }
  }

  &__controlsNow {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
    background: rgba(255, 255, 255, 0.2);
    padding: 11px 12px;
    border-radius: 8px;
    width: max-content;
    cursor: pointer;

    &.active {
      background: #FFF;
      color: #020850;
    }
  }

  &__controlsNowText {
    color: white;
    position: relative;
    padding-left: 24px;

    &:after {
      position: absolute;
      content: '';
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background: rgba(255, 255, 255, 0.2);
    }

    &.active {
      &:after {
        background: #FE8282;
      }
    }
  }

  &__controlsRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__controlCol {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__controlsBlock {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.10);
    padding: 2px;
    display: flex;
    align-items: center;
  }

  &__controlsBlockItem {
    padding: 6px 12px;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;

    &.upper {
      text-transform: uppercase;
    }

    &.active {
      border-radius: 6px;
      background: #FFF;
      color: #020850;
    }
  }

  &__controlsRangePicker {
    background: transparent;
    padding: 0;
    margin: 0;
    height: auto;
    font-size: 14px;

    .ant-picker-active-bar,
    .ant-picker-suffix {
      display: none;
    }
  }
}

.ant-slider {
  margin: 0;
}

.ant-slider-rail {
  height: 8px !important;
  background: #616277 !important;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}