.tabs-popup {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #2F80ED;
  border-radius: 4px;
  position: relative;

  .checkbox {
    position: absolute;
    opacity: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  &__tab {
    padding: 12px;
    width: 50%;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    color: #2F80ED;
    text-transform: uppercase;

    &.active {
      background: #2F80ED;
      color: #FFFFFF;
    }
  }
}
