::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #FAFAFA;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 5px;
  background: #EFEFEF;
}

* {
  box-sizing: border-box;
}

body {
  height: 100vh;
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: $black;
  -webkit-font-smoothing: antialiased;
}

body,
.layout,
#app-wrapper {
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    width: 0;
    height: 0;
    display: none;
  }
}

#app-wrapper {
  display: block;
  width: 100%;
  height: 100vh;
}

.no_padding {
  padding: 0 !important;
}

.layout {
  padding: 144px 0 0 168px;
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: stretch;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 99998;
    transition: 0.5s ease;
    opacity: 0;
    background: rgba(196, 196, 196, 0.3);
    visibility: hidden;
  }

  &_overlay::before {
    opacity: 1;
    visibility: visible;
  }

  &__h1 {
    margin-bottom: 40px;
  }
}

.h1 {
  font-weight: 400;
  font-size: 36px;
  color: $black;

  &_calendar {
    margin-right: 10px;
  }
}

.page-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.d-flex {
  display: flex;
}

.fx-ww {
  flex-wrap: wrap;
}

.workers-content,
.page-content {
  width: 100%;
  height: calc(100% - 66px);
}

.workers-content {
  padding-right: 48px;
}

.Toastify {
  position: relative;
  z-index: 99999 !important;
}
