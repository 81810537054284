.mapUser {
  padding: 8px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: .3s ease-in-out;

  &:not(.withoutHover) {
    cursor: default;
    &:hover {
      background: #EFEFEF;

      .map-user__avatar {
        background: #DFDFDF;
      }
    }
  }

  &__avatar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    min-width: 48px;
    max-width: 48px;
    height: 48px;
    min-height: 48px;
    max-height: 48px;
    background: #EFEFEF;
    border-radius: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-right: 16px;
    transition: .3s ease-in-out;

    &:after {
      position: absolute;
      border-radius: 100%;
      content: '';
      width: 12px;
      height: 12px;
      background: #B0B2B3;
      left: 2px;
      top: 2px;
    }

    &.offline {
      background: #FFF3F3;
      &:after {
        background: #FE8282;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding-right: 40px;
    width: calc(100% - 48px - 16px);
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__icon {
    display: flex;
    margin-right: 4px;
  }

  &__name {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }

  &__location {
    position: relative;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    color: #A5A7A8;
  }

  &__geoError {
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 5px;
    color: #FE8282;
  }

  &__btn {
    display: flex;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &.error {
    .mapUser__location {
      color: #FE8282;
    }
  }
}
