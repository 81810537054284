.labels {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.labels-status {
  border-radius: 4px;
  padding: 2px 8px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}


@each $name, $colors in $status {
  .status__#{$name} {
    color: nth($colors, 1) !important;
    background-color: nth($colors, 2) !important;
  }
}
