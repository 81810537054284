.applications {
  overflow: hidden;
  width: calc(100% + 96px);
  position: relative;
  left: 0;
  padding-left: 48px;
  padding-right: 48px;

  &__controls {
    margin-bottom: 24px;
  }

  &__title {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #333333;
  }

  &__applications {
    overflow: initial;
    position: relative;
    -ms-overflow-style: none;

    .swiper-slide {
      width: auto;
    }

    .swiper-button-prev {
      left: auto;
      right: 40px;
      top: -56px;
      cursor: pointer;
      width: 40px;
      height: 40px;
      margin: 0;

      &:after {
        all: initial;
        content: '';
        width: 24px;
        height: 24px;
        pointer-events: none;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' class='new-button__icon'%3E%3Cpath d='M15.0003 20.2497C14.8015 20.247 14.6109 20.1701 14.4659 20.0341L6.96594 12.5341C6.8251 12.3919 6.74609 12.1999 6.74609 11.9997C6.74609 11.7996 6.8251 11.6075 6.96594 11.4654L14.4659 3.96535C14.6117 3.84575 14.7967 3.78464 14.9849 3.79388C15.1732 3.80313 15.3514 3.88208 15.4847 4.01539C15.618 4.14869 15.6969 4.3268 15.7062 4.51509C15.7154 4.70338 15.6543 4.88838 15.5347 5.0341L8.55969 11.9997L15.5347 18.9654C15.6755 19.1075 15.7545 19.2996 15.7545 19.4997C15.7545 19.6999 15.6755 19.8919 15.5347 20.0341C15.3897 20.1701 15.1991 20.247 15.0003 20.2497Z' fill='%232F80ED'%3E%3C/path%3E%3C/svg%3E");
      }

      &.swiper-button-disabled {
        opacity: 1;

        &:after {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' class='new-button__icon'%3E%3Cpath d='M15.0003 20.2497C14.8015 20.247 14.6109 20.1701 14.4659 20.0341L6.96594 12.5341C6.8251 12.3919 6.74609 12.1999 6.74609 11.9997C6.74609 11.7996 6.8251 11.6075 6.96594 11.4654L14.4659 3.96535C14.6117 3.84575 14.7967 3.78464 14.9849 3.79388C15.1732 3.80313 15.3514 3.88208 15.4847 4.01539C15.618 4.14869 15.6969 4.3268 15.7062 4.51509C15.7154 4.70338 15.6543 4.88838 15.5347 5.0341L8.55969 11.9997L15.5347 18.9654C15.6755 19.1075 15.7545 19.2996 15.7545 19.4997C15.7545 19.6999 15.6755 19.8919 15.5347 20.0341C15.3897 20.1701 15.1991 20.247 15.0003 20.2497Z' fill='%23B0B2B3'%3E%3C/path%3E%3C/svg%3E");
        }
      }
    }

    .swiper-button-next {
      left: auto;
      right: 0;
      top: -56px;
      cursor: pointer;
      width: 40px;
      height: 40px;
      margin: 0;

      &:after {
        all: initial;
        content: '';
        width: 24px;
        height: 24px;
        pointer-events: none;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' class='new-button__icon'%3E%3Cpath d='M9.00031 20.2497C8.80153 20.247 8.61093 20.1701 8.46594 20.0341C8.3251 19.8919 8.24609 19.6999 8.24609 19.4997C8.24609 19.2996 8.3251 19.1075 8.46594 18.9654L15.4409 11.9997L8.46594 5.0341C8.34634 4.88838 8.28522 4.70338 8.29447 4.51509C8.30372 4.3268 8.38267 4.14869 8.51597 4.01539C8.64927 3.88208 8.82739 3.80313 9.01568 3.79388C9.20397 3.78464 9.38897 3.84575 9.53469 3.96535L17.0347 11.4654C17.1755 11.6075 17.2545 11.7996 17.2545 11.9997C17.2545 12.1999 17.1755 12.3919 17.0347 12.5341L9.53469 20.0341C9.3897 20.1701 9.1991 20.247 9.00031 20.2497V20.2497Z' fill='%232F80ED'%3E%3C/path%3E%3C/svg%3E");
      }

      &.swiper-button-disabled {
        opacity: 1;

        &:after {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' class='new-button__icon'%3E%3Cpath d='M9.00031 20.2497C8.80153 20.247 8.61093 20.1701 8.46594 20.0341C8.3251 19.8919 8.24609 19.6999 8.24609 19.4997C8.24609 19.2996 8.3251 19.1075 8.46594 18.9654L15.4409 11.9997L8.46594 5.0341C8.34634 4.88838 8.28522 4.70338 8.29447 4.51509C8.30372 4.3268 8.38267 4.14869 8.51597 4.01539C8.64927 3.88208 8.82739 3.80313 9.01568 3.79388C9.20397 3.78464 9.38897 3.84575 9.53469 3.96535L17.0347 11.4654C17.1755 11.6075 17.2545 11.7996 17.2545 11.9997C17.2545 12.1999 17.1755 12.3919 17.0347 12.5341L9.53469 20.0341C9.3897 20.1701 9.1991 20.247 9.00031 20.2497V20.2497Z' fill='%23B0B2B3'%3E%3C/path%3E%3C/svg%3E");
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__none {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    > p {
      margin-bottom: 8px;
    }

    > span {
      color: #A5A7A8;
    }
  }
}
