.schedule-table {
  width: 100%;
  height: calc(100vh - 450px);
  min-height: 600px;

  .table-cell {
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #efefef;

    &.isToday {
      background-color: #f5f9fe;
    }
  }

  .currentDate {
    width: 100%;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    padding-left: 26px;
    text-align: left;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .scrollable-table {
    outline: none !important;
  }

  .scrollable-column {
    width: 55px;
    height: 100%;
    flex-shrink: 0;
    position: absolute;
    top: 0;
    left: 0;

    &__date {
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      border-bottom: 1px solid #efefef;
      line-height: 20px;
      font-size: 12px;
      color: #a5a7a8;
      cursor: pointer;

      .day {
        font-size: 16px;
        color: #333;
      }

      &.isToday {
        background-color: #f5f9fe;
        font-weight: 500;

        span {
          color: #2f80ed !important;
        }
      }
    }
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    &:hover {
      svg {
        opacity: 1;
        visibility: visible;
      }
    }

    &_header {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.16px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: $txt200;

      .schedule-table__col {
        height: 70px;
        padding-bottom: 15px;
        padding-top: 10px;
        cursor: default;

        &_selected {
          background-color: #F5F9FE;
          color: #2F80ED;
          font-weight: 500;

          .day {
            color: #2F80ED;
          }
        }

        &_days {
          padding: 0;
          height: auto;
        }
      }
    }
  }

  &__col {
    border-bottom: 1px solid $light-grey;
    padding: 0;
    height: 56px;
    display: flex;
    align-items: center;
    position: relative;

    &_selected {
      background-color: #F5F9FE;
    }

    &_avatar,
    &_name,
    &_days-nav,
    &_create-schedule {
      z-index: 1;
    }

    &_avatar {
      min-width: 100px;
      max-width: 100px;
      justify-content: center;
    }

    &_name {
      min-width: 368px;
      max-width: 368px;

      &.full-width{
        min-width: 400px;
        max-width: 400px;
      }
    }

    &_create-schedule {
      width: 32px;
      height: 56px;

      svg {
        opacity: 0;
        visibility: hidden;
        cursor: pointer;
      }
    }

    &_days-nav {
      height: 70px;
      min-width: max-content;
      max-width: 541px;
      padding-right: 16px;
      align-items: flex-start;
      justify-content: flex-end;
      position: static;
    }

    &_days {
      display: flex;
      border: none;
      transition: left 0.3s ease;
    }

    &_day {
      line-height: 25px;
      min-width: 55px;
      max-width: 55px;
      flex-direction: column;
      text-align: center;
      justify-content: center;

      .day {
        font-size: 16px;
        color: #333333;
      }
    }
  }

  &__cell {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    margin: 0 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;

    &_cant-edit {
      cursor: default;
    }

    &_status {
      &_default {
        background-color: $light-grey;
        border: 1px solid $light-grey;
      }

      &_partTime {
        background-color: $lemon;
        border: 1px solid $lemon;
      }

      &_warning {
        color: white;
        background-color: $salmon;
        border: 1px solid $salmon;
      }

      &_partTimeCancel {
        color: white;
        background-color: $salmon;
        border: 1px solid $salmon;
      }
    }

    &_empty {
      border: 1px solid $light-grey;
      background: none;
    }

    &_vacation {
      background-image: url("data:image/svg+xml,%3Csvg width='7' height='7' viewBox='0 0 7 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_3619_53583)'%3E%3Crect y='6' width='1' height='1' fill='%23C6C6C6'/%3E%3Crect y='5' width='1' height='1' fill='%23C6C6C6'/%3E%3Crect x='1' y='5' width='1' height='1' fill='%23C6C6C6'/%3E%3Crect x='1' y='4' width='1' height='1' fill='%23C6C6C6'/%3E%3Crect x='2' y='4' width='1' height='1' fill='%23C6C6C6'/%3E%3Crect x='2' y='3' width='1' height='1' fill='%23C6C6C6'/%3E%3Crect x='3' y='3' width='1' height='1' fill='%23C6C6C6'/%3E%3Crect x='3' y='2' width='1' height='1' fill='%23C6C6C6'/%3E%3Crect x='4' y='2' width='1' height='1' fill='%23C6C6C6'/%3E%3Crect x='4' y='1' width='1' height='1' fill='%23C6C6C6'/%3E%3Crect x='5' y='1' width='1' height='1' fill='%23C6C6C6'/%3E%3Crect x='5' width='1' height='1' fill='%23C6C6C6'/%3E%3Crect x='6' width='1' height='1' fill='%23C6C6C6'/%3E%3Crect x='6' y='6' width='1' height='1' fill='%23C6C6C6'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3619_53583'%3E%3Crect width='7' height='7' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      cursor: default;
    }

    &_isBefore {
      opacity: .5;
      pointer-events: none;
    }

    &_past {
      cursor: default;
    }

    &_past:not(&_empty) {
      opacity: 0.5;
    }
  }
}

.ReactVirtualized__Grid__innerScrollContainer {
  min-height: 552px !important;
}
