.extra-smena {

  &-item {
    padding: 12px 0;
    border-bottom: 1px solid #EFEFEF;

    &__date {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
    }

    &__smena-time {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      color: #333333;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    &__smena {
      position: relative;
      padding-right: 8px;
    }

    &__time {
      padding-left: 8px;
      position: relative;
    }

    &__buttons {
      display: flex;
    }

    &__button {
      min-width: 100px;
      padding: 12px 16px;
      margin-right: 10px;
      text-transform: uppercase;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
