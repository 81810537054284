.ant-wrapper-block {
  display: flex;

  &__item {
    width: calc((100% - 30px) / 2);
    margin-right: 30px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__error {
    color: #FE8282;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.16px;
    margin-top: 8px;
  }
}

.ant-picker {
  border: none !important;
  box-shadow: none !important;
  border-radius: 5px;
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  line-height: 2;
  margin-bottom: 15px;
  background: #FAFAFA;
  color: #A5A7A8;
  transition: 0.3s ease;
  height: 54px;

  input {

    &:focus {
      background: transparent !important;
    }
  }

  &.error {
    outline: 1px solid #FE8282;
  }
}

.ant-picker-dropdown {
  z-index: 9999;
}
