.new-button {
  border-radius: 4px;
  background: transparent;

  &__icon {
    margin-right: 8px;
  }

  &__text {
    white-space: nowrap;
  }

  &.default {
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;

    .new-button__text {
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-transform: uppercase;
    }

    &:disabled {
      pointer-events: none;
      background: #B0B2B3 !important;
    }
  }

  &.reverse {
    flex-direction: row-reverse;

    .new-button__icon {
      margin-right: 0;
      margin-left: 8px;
    }
  }

  &.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;

    .new-button__text {
      color: #2F80ED;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &.back {
    padding: 8px;
    .new-button__text {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;

    }
  }

  &.pagination {
    .new-button__icon {
      path {
        fill: #2F80ED
      }
    }

    &:disabled {
      pointer-events: none;
      cursor: not-allowed;

      .new-button__text {
        color: #B0B2B3;
      }

      .new-button__icon {
        path {
          fill: #B0B2B3
        }
      }
    }
  }

  &.login {
    padding: 16px 24px;
    width: 320px;
    text-transform: uppercase;
    transition: .2s ease-in-out;

    .new-button__text {
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }

    &:disabled {
      pointer-events: none;
      background: #B0B2B3 !important;
    }
  }

  &.calendar {
    padding: 4px 8px;

    .new-button__icon {
      margin: 0;
    }
  }

  &.calendar-next,
  &.calendar-prev {
    padding: 8px;

    .new-button__icon {
      margin: 0;
    }

    &:disabled {
      pointer-events: none;
      cursor: not-allowed;

      .new-button__icon {
        path {
          fill: #B0B2B3
        }
      }
    }
  }

  &.calendar-next-small,
  &.calendar-prev-small {
    padding: 0;

    .new-button__icon {
      margin: 0;
    }
  }

  &.download {
    padding: 12px 24px;
    text-transform: uppercase;

    .new-button__text {
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }

    &:disabled {
      pointer-events: none;
      background: #B0B2B3 !important;
    }
  }

  &.none {
    display: none;
    pointer-events: none;
  }

  &.small {
    padding: 12px 16px;
  }

  &.dialog {
    padding: 8px;
  }
}
