.chartLine {

  &__legend {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__lineAll {
    width: 100%;
    position: relative;
    min-height: 32px;
    background: #EFEFEF;
    border-radius: 4px;
    overflow: hidden;
  }

  &__lineTotal {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__lineFilling {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: #2F80ED;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s ease-in-out;
  }

  &__lineFillingTotal {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-right: 4px;
  }

  &__lineFillingPercent {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 2px 4px;
    background: #0F58BA;
    border-radius: 4px;
  }
}
