.task-list {

  &__btn {
    cursor: pointer;
  }

  &__list {
    background: $white;
    box-shadow: 0 0 16px rgba(96, 96, 96, 0.25);
    border-radius: 5px;
    position: absolute;
    min-width: 237px;
    right: 0;
    padding: 10px 0;
    opacity: 0;
    pointer-events: none;

    &.open {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__list-item {
    font-size: 16px;
    line-height: 20px;
    padding: 10px 15px;
    cursor: pointer;
    user-select: none;

    &:hover {
      background: #EFEFEF;
    }
  }
}
