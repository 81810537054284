.task-start {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 45px;

  &__title {
    margin-bottom: 4px;
    line-height: 24px;
  }

  &__comment {
    margin-bottom: 15px;

    .textarea {
      min-height: 100px;
      max-height: 100px;
    }
  }

  .file-input {
    display: block;
  }

  &__inner {
    display: flex;
    justify-content: flex-end;
  }

  .files {
    margin-bottom: 24px;
  }

  &__mobile {
    background: #FFF3F3;
    border-radius: 5px;
    width: 100%;
    padding: 8px 0;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #FE8282;
  }
}
