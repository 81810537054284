.notification {

  &__item {
    display: block;
    padding: 8px 0;
    border-bottom: 1px solid #efefef;

    &.active {
      color: #2f80ed
    }
  }

  &__title {
    font-size: 20px;
    line-height: 28px;
    color: #333333;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin-top: 48px;
    margin-bottom: 24px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__time {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      display: block;
      font-weight: 600;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__none {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A5A7A8;
  }

  &:nth-last-of-type(1) {
    margin-bottom: 0
  }
}
