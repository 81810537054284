.legends {

  &__items {

  }

  &__item {
    position: relative;
    padding-left: 16px;
    margin-right: 24px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__itemTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
  }

  &__itemCircle {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }

  &__itemInfo {
    display: flex;
    align-items: center;

    > p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-right: 4px;
    }

    > span {
      display: flex;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #2F80ED;
      padding: 2px 4px;
      background: #EBF3FE;
      border-radius: 4px;
    }
  }
}
