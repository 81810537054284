.asideNav {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__link {
    text-decoration: none;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 11px 0;
    margin-bottom: 24px;
    opacity: 0.4;

    transition: 0.3s ease;

    &_last {
      margin-top: auto;
      margin-bottom: 0;
      padding: 0;
      opacity: 1;
      a {
        padding: 11px 0;
        opacity: 0.4;
        transition: 0.3s ease;
        &:hover {
          opacity: 1;
        }
      }
      a:not(:first-child) {
        margin-top: 24px;
      }
    }

    &:hover:not(&_last),
    &_active {
      opacity: 1;
    }
  }

  &__ico {
    display: block;
    margin: 0 auto;
    margin-bottom: 4px;
  }

  &__link-text {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #ffffff;
    line-height: 2;
  }
}
