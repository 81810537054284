.contentPriority {
  display: flex;
  align-items: center;
  gap: 8px;

  &.contentPriority0 {
    color: #2F80ED;
  }

  &.contentPriority1 {
    color: #FA9131;
  }

  &.contentPriority2 {
    color: #E35B5B;
  }
}