.layout-screen {
  width: 100%;
  height: calc(100vh - 144px);
  padding-right: 48px;

  &__title {
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    color: #333333;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
  }

  &__selects {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 48px;
  }

  &__controls {
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    .tabs {
      width: max-content;
    }
  }

  &__select {
    max-width: 256px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 48px;
  }

  &__contentMap {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding-bottom: 0;

    .mapAside {
      width: 100%;
      background: #FFFFFF;
      height: calc(100vh - 376px);
    }

    .mapContainer {
      width: 100%;
      height: calc(100vh - 144px);
      position: relative;
      margin-right: -48px;
    }
  }

  &__asideWrapper {
    width: 454px;
    flex-shrink: 0;
    margin-right: 48px;
  }

  &__paginationTop {
    margin-bottom: 24px;
  }

  &__paginationBottom {
    margin-top: 24px;
  }

  .search-input {
    margin-top: 0;
    margin-bottom: 24px;
  }

  .list-table__row_header_filters {
    margin-bottom: 48px;
  }

  .tasksStatistic {
    margin-bottom: 48px;
  }

  .templates {
    padding-bottom: 48px;
  }

  &__table {

  }

  &__tabs {
    margin-bottom: 28px;
  }

  &__filter {
    display: flex;
    align-items: flex-start;
  }
}
