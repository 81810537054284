.tasksStatistic {
  width: 100%;
  font-weight: 400;
  font-size: 24px;

  &__title {
    margin-bottom: 24px;
  }

  &__wrapper {
    display: flex;
  }

  &__inner {
    width: 50%;
    margin-right: 24px;
    padding-right: 24px;
    border-right: 1px solid #EFEFEF;

    &:last-of-type {
      margin-right: 0;
      padding-right: 0;
      border-right: none;
    }
  }

  &__flex {
    column-gap: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .new-select {
      width: 50%;
    }
  }

  &__subtitle {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
  }

  &__users {
    position: relative;
  }

  &__btn {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2F80ED;
    cursor: pointer;
    padding: 4px;

    > p {
      margin-right: 8px;
    }
  }

  &__popup {
    z-index: 5;
    position: absolute;
    top: 100%;
    right: 0;
    width: 400px;
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  &__popupHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &__popupTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__popupTotal {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__popupWrapper {
    max-height: 420px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__popupInner {
    height: 100%;
  }

  &__popupItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
  }

  &__popupItemName {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__popupItemInfo {
    display: flex;
    align-items: center;
  }

  &__popupItemTotal {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-right: 8px;
  }

  &__popupItemCircle {
    position: relative;
    width: 24px;
    height: 24px;

    :root {
      --fillPercent: 0;
    }

    &:before {
      will-change: transform;
      content: '';
      position: absolute;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      background-image: conic-gradient(#2F80ED 0 calc(var(--fillPercent) * 360deg), #EFEFEF calc(var(--fillPercent) * 360deg) 1turn);
    }

    &:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      background: #FFFFFF;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

  &__doughnut {
    display: flex;
    margin-top: 24px;
    gap: 24px;
    flex-wrap: wrap;
  }

  &__tasks {
    width: 283px;
  }

  &__tasksList {
    width: calc(100% - 283px);
    padding-left: 24px;
  }

  &__tasksListContent {
    margin-top: 16px;
  }

  &__tasksListTotal {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #A5A7A8;
    white-space: nowrap;
  }

  &__select {
    margin-bottom: 0 !important;

    .input-block__title {
      display: none;
    }

    .input {
      padding: 0 32px 0 0;
      color: #333333;
      cursor: pointer;
      background: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.0013 6.7513C5.80247 6.75044 5.61184 6.67194 5.47005 6.53255L0.470051 1.53255C0.329155 1.39165 0.25 1.20056 0.25 1.0013C0.25 0.802043 0.329155 0.610947 0.470051 0.470051C0.610947 0.329155 0.802044 0.25 1.0013 0.25C1.20056 0.25 1.39165 0.329155 1.53255 0.470051L6.0013 4.9388L10.4701 0.470051C10.6109 0.329155 10.802 0.25 11.0013 0.25C11.2006 0.25 11.3917 0.329155 11.5326 0.470051C11.6734 0.610947 11.7526 0.802043 11.7526 1.0013C11.7526 1.20056 11.6734 1.39165 11.5326 1.53255L6.53255 6.53255C6.39076 6.67194 6.20013 6.75044 6.0013 6.7513Z' fill='%23B0B2B3'/%3E%3C/svg%3E%0A") no-repeat calc(100% - 0px) !important;

      &:focus {
        color: #333333;
      }
    }
  }

  &__tasksListHeader {
    display: flex;
    align-items: center;
    color: #A5A7A8;
    border-bottom: 1px solid #EFEFEF;
  }

  &__tasksListWrapper {
    height: 225px;
    overflow: hidden;
    overflow-y: auto;
  }

  &__tasksListInner {
    height: 100%;
  }

  &__noTasks {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #A5A7A8;
  }

  &__tasksListName {
    padding: 10px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__tasksListTask {
    padding: 10px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    width: calc(100% - 200px);
  }

  &__tasksListItem {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EFEFEF;

    .tasksStatistic__tasksListTask {
      color: #2F80ED;
    }
  }

  &__doughnutItem {
    width: calc((100% - 24px) / 2);
    min-width: 315px;

    .chartDoughnut {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .chartDoughnut__wrapper {
      min-height: 144px;
    }
  }
}
