.popup-screen {
  height: 100%;

  .aside-popup__body{
    height: 100%;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .new-button{
      margin-left: 0;
    }
  }
}
