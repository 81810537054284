.spoiler {
  display: block;
  transition: 0.5s ease;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 0;
    color: #333333;
    font-size: 20px;
    line-height: 28px;
    border-bottom: 1px solid #EBEBEB;
    width: 100%;
    cursor: pointer;
  }

  &__arrow {
    transition: .3s all;
  }

  &__body {
    opacity: 0;
    pointer-events: none;
    height: 0;
    margin-bottom: 0;
    transition: 0.3s ease-in-out;
  }

  &_active &__body {
    padding-top: 24px;
    margin-bottom: 24px;
    height: 100%;
    opacity: 1;
    pointer-events: all;
  }

  &_active &__arrow {
    transform: rotate(180deg);
  }
}

input,
.input,
.textarea {
  border: 1px solid transparent;
  border-radius: 4px;
  display: block;
  width: 100%;
  padding: 11px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
  background: #FAFAFA;
  color: #333333;
  transition: 0.3s ease;

  &::placeholder {
    color: #A5A7A8;
  }

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  &:focus,
  &:active {
    background: #F5F9FE;
    color: #2f80ed;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.0013 6.7513C5.80247 6.75044 5.61184 6.67194 5.47005 6.53255L0.470051 1.53255C0.329155 1.39165 0.25 1.20056 0.25 1.0013C0.25 0.802043 0.329155 0.610947 0.470051 0.470051C0.610947 0.329155 0.802044 0.25 1.0013 0.25C1.20056 0.25 1.39165 0.329155 1.53255 0.470051L6.0013 4.9388L10.4701 0.470051C10.6109 0.329155 10.802 0.25 11.0013 0.25C11.2006 0.25 11.3917 0.329155 11.5326 0.470051C11.6734 0.610947 11.7526 0.802043 11.7526 1.0013C11.7526 1.20056 11.6734 1.39165 11.5326 1.53255L6.53255 6.53255C6.39076 6.67194 6.20013 6.75044 6.0013 6.7513Z' fill='%23B0B2B3'/%3E%3C/svg%3E%0A") #FAFAFA no-repeat calc(100% - 18px) !important;

  &.no-bg {
    background: none #FAFAFA !important;
  }

  &:active,
  &:focus {
    background-color: #F5F9FE !important;
  }
}

.textarea {
  height: 172px;
  max-height: 172px;
  min-height: 172px;
  max-width: 100%;
  min-width: 100%;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  font-family: sans-serif;
  resize: none;
  padding: 12px 16px;

  &::placeholder {
    font-size: 14px;
    color: #A5A7A8;
  }

  &:disabled {
    background: transparent;
    padding-left: 0;
  }
}

button {
  cursor: pointer;
}

.button {
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  display: block;
  padding: 12px 16px;
  border-radius: 4px;
  background: #2f80ed;
  color: #ffffff;

  &.disabled {
    pointer-events: none;
    background: #B0B2B3;
  }

  &:disabled {
    pointer-events: none;
    background: #B0B2B3;
  }

  &_primary,
  &_secondary {
    width: 100px;
    height: 40px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: lowercase;

    &:active {
      transform: scale(0.97);
    }

    &:disabled.button_ghost {
      background: none !important;
      border: 1px solid #B0B2B3;
      color: #B0B2B3;
    }
  }

  &_primary {
    background: #2F80ED;

    &.button_ghost {
      background: none;
      border: 1px solid #2F80ED;
      color: #2F80ED;
    }
  }

  &_blue {
    background: none;
    border: 1px solid #2F80ED;
    color: #2F80ED;
  }

  &_red {
    background: $salmon;
  }

  &_remove {
    background: none;
    border: 1px solid $salmon;
    color: $salmon;
  }

  &_secondary {
    background: $salmon;

    &.button_ghost {
      background: none;
      border: 1px solid $salmon;
      color: $salmon;
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__check {
    width: 24px;
    height: 24px;
    padding: 3px;
    margin-right: 6px;
    position: relative;
    display: block;

    &::before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      flex-basis: 19px;
      border-radius: 2px;
      border: 1px solid #AAACAD;
      box-sizing: border-box;
    }

    svg {
      opacity: 0;
      transition: 0.3s;
      position: absolute;
      top: 3px;
      left: 3px;
      width: 18px;
      height: 18px;
    }
  }

  &__text {
    height: 18px;
    line-height: 18px;
    vertical-align: middle;
    display: inline-block;
  }

  &__input {
    display: none;
  }

  &__input:checked + & {
    &__check::before {
      border-color: #2f80ed;
    }

    &__check svg {
      opacity: 1;
    }
  }

  &--disable {
    pointer-events: none;

    .checkbox__check {
      &::before {
        background: #DDDDDD;
        border: none;
      }

      svg {
        path {
          fill: #B0B2B3;
        }
      }
    }
  }
}

.input-block {
  margin-bottom: 24px;
  position: relative;

  &__date {
    > span {
      background: #F5F9FE !important;
      color: #2f80ed !important;
      padding: 10px 15px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.16px;
      margin-right: 10px;
      display: inline-flex;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &.blue > input {
    background: #F5F9FE !important;
    color: #2f80ed !important;
  }

  &.error {
    .input {
      border-color: $salmon;
    }
  }

  &__title {
    margin-bottom: 4px;
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
  }

  &.personal-number {
    input {
      padding-left: 60px;
    }
  }

  .inputContainer {
    display: flex;
    align-items: center;
    border-radius: 5px;

    &.inputWithPrefix {
      padding-left: 15px;
      background-color: #FAFAFA;
      color: #333333;

      input {
        width: 100%;
        padding-left: 0 !important;
      }
    }

    &.errorBorder {
      outline: 1px solid #FE8282;
    }

    &.prefixFocus {
      background: #F5F9FE;
      color: #2f80ed;
    }

    &.inputTypePassword {
      .input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: (320px - 16px - 36px);
      }

      .inputVisibilityPassword {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FAFAFA;
        cursor: pointer;
      }
    }
  }

  .input_gray {
    display: block;
    width: 320px;
  }

  &__password-wrapper {
    display: flex;
    align-items: stretch;

    .input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      width: (320px - 16px - 36px);
    }
  }

  &__password-visibility {
    flex-grow: 1;
    background: #FAFAFA;
    border-radius: 0 6px 6px 0;
    padding: 0 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  #password-visibility {
    display: none;
  }

  &.small {
    select {
      background: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.0013 6.7513C5.80247 6.75044 5.61184 6.67194 5.47005 6.53255L0.470051 1.53255C0.329155 1.39165 0.25 1.20056 0.25 1.0013C0.25 0.802043 0.329155 0.610947 0.470051 0.470051C0.610947 0.329155 0.802044 0.25 1.0013 0.25C1.20056 0.25 1.39165 0.329155 1.53255 0.470051L6.0013 4.9388L10.4701 0.470051C10.6109 0.329155 10.802 0.25 11.0013 0.25C11.2006 0.25 11.3917 0.329155 11.5326 0.470051C11.6734 0.610947 11.7526 0.802043 11.7526 1.0013C11.7526 1.20056 11.6734 1.39165 11.5326 1.53255L6.53255 6.53255C6.39076 6.67194 6.20013 6.75044 6.0013 6.7513Z' fill='white'/%3E%3C/svg%3E%0A") $salmon no-repeat calc(100% - 14px) !important;
      color: $pure-white;
      padding: 6px 36px 6px 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    &.default {
      select {
        color: #2F80ED;
        background: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.0013 6.7513C5.80247 6.75044 5.61184 6.67194 5.47005 6.53255L0.470051 1.53255C0.329155 1.39165 0.25 1.20056 0.25 1.0013C0.25 0.802043 0.329155 0.610947 0.470051 0.470051C0.610947 0.329155 0.802044 0.25 1.0013 0.25C1.20056 0.25 1.39165 0.329155 1.53255 0.470051L6.0013 4.9388L10.4701 0.470051C10.6109 0.329155 10.802 0.25 11.0013 0.25C11.2006 0.25 11.3917 0.329155 11.5326 0.470051C11.6734 0.610947 11.7526 0.802043 11.7526 1.0013C11.7526 1.20056 11.6734 1.39165 11.5326 1.53255L6.53255 6.53255C6.39076 6.67194 6.20013 6.75044 6.0013 6.7513Z' fill='%232F80ED'/%3E%3C/svg%3E%0A") #EBF3FE no-repeat calc(100% - 14px) !important;
      }
    }
  }
}

.popover {
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 16px;
  min-height: 16px;
  top: 0;
  left: 0;
  outline: 0;
  transition: visibility, opacity 211ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 141ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 16px rgba(96, 96, 96, 0.08);
  white-space: nowrap;
  opacity: 0;
  z-index: 3;
  transform: scale(0.95);
  visibility: hidden;

  &__active {
    opacity: 1;
    transform: scale(1);
    visibility: visible;

    & > .popover__body {
      opacity: 1;
      visibility: visible;
    }
  }

  &__body {
    opacity: 0;
    visibility: hidden;
    transition: visibility, opacity 211ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 141ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: relative;
  }
}

.template_hint {
  padding: 4px 16px 4px 16px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #EBF3FE;
  }

  p {
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 4;
  }

  .actions {
    margin-left: 20px;
    display: flex;
    flex: 1;
    justify-items: flex-end;

    button {
      &:not(:first-of-type) {
        margin-left: 20px;
      }
    }
  }
}

.error-message {
  color: $salmon;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.16px;
  margin-top: 8px;
}

.nav-arrows {
  display: flex;

  &__button {
    display: flex;
    align-items: center;

    &:disabled {
      opacity: 0.42;
      cursor: default;
    }

    &_next {
      margin-left: 15px;
    }
  }
}

.text {
  &_blue {
    color: #2F80ED;
  }

  &_red {
    color: #FE8282;
  }

  &_fs20 {
    font-size: 20px;
  }

  &_pointer {
    cursor: pointer;
  }

  &_mt25 {
    margin-top: 25px !important;
  }

  &_mt56 {
    margin-top: 56px !important;
  }
}
