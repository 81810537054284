.task-no-time {
  padding: 16px 16px 12px;
  background: #FFFFFF;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: relative;

  &__controls {
    position: absolute;
    right: 8px;
    top: 8px;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: .4s ease-in-out;
  }

  &__control {
    display: flex;
    padding: 8px;
    cursor: pointer;
    margin-right: 8px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .contentPriority {
    margin-right: 16px;
  }

  &__time {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
  }

  &__period {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    margin-top: 10px;
  }

  &__title {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }

  &__block {
    display: flex;
  }

  &__block-item {
    width: calc((100% - 8px) / 2);
    margin-right: 8px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__block-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }

  &:hover {
    .task-no-time__controls {
      opacity: 1;
      pointer-events: all;
    }
  }
}
