.pagination {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  width: 100%;

  &_limit {
    display: flex;
    align-items: center;
    column-gap: 8px;

    &-span {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.16px;
      font-feature-settings: 'pnum'on, 'lnum'on;
      color: #A5A7A8;
    }

    &-input {
      text-align: center;
      width: 64px;
      padding: 4px 16px;
      color: #343940;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
}
