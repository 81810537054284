.currentTimeLine {
  position: absolute;
  left: -60px;
  width: calc(100% + 60px);
  height: 2px;
  background: rgba(47, 128, 237, 0.5);
  z-index: 5;

  &:after {
    content: "";
    position: absolute;
    left: -8px;
    bottom: -8.8px;
    border: 10px solid transparent;
    transform: rotate(270deg);
    border-top: 10px solid rgba(47, 128, 237, 0.5);
  }
}