.task-no-time-in-work-info {
  display: flex;
  width: 100%;

  &__task {
    max-width: 330px;
    min-width: 330px;
    width: 100%;
    margin-right: 30px;
  }

  &__workers {
    width: 100%;
    padding-left: 46px;

    .task-no-time-inside {
      margin-bottom: 12px;
      padding-bottom: 11px;
      border-bottom: 1px solid #EBEBEB;

      &:last-of-type {
        margin: 0;
        padding: 0;
        border: none;
      }
    }
  }

  &__block {
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__block-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A5A7A8;
    margin-bottom: 2px;
  }

  &__block-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    margin-bottom: 16px;
    word-break: break-word;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__title {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }

  .performers {
    margin-bottom: 16px;
  }

  .checkbox {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &__text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #333333;
      height: auto;
    }
  }

  &__history {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2F80ED;
    cursor: pointer;
    margin-top: 24px;
  }
}
