.ant-tooltip {
  max-width: none;

  .ant-tooltip-inner {
    background: #FFFFFF;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    padding: 8px;
    white-space: nowrap;
    width: max-content;

    .ant-tooltip-text {
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .ant-tooltip-arrow {
    width: 16px;
    height: 8px;
    bottom: 0;

    .ant-tooltip-arrow-content {
      background: #FFFFFF;
      width: 16px;
      height: 8px;
      box-shadow: -3px -3px 35px rgb(0 0 0 / 7%);
    }
  }
}

.ant-tooltip-placement-bottom, .ant-tooltip-placement-bottomLeft, .ant-tooltip-placement-bottomRight {
  .ant-tooltip-arrow {
    top: 0;
  }
}
