.workingShiftsTypes {
  width: 24px;
  height: 24px;
  background-size: cover;
  background-position: 50% 50%;
  margin: 0 auto;

  &--shift_icon {
    background-image: url("../../assets/shift_icon.svg");
  }
  &--sunny_icon {
    background-image: url("../../assets/sunny_icon.svg");
  }
  &--night_icon {
    background-image: url("../../assets/night_icon.svg");
  }
  &--sunrise_icon {
    background-image: url("../../assets/sunrise_icon.svg");
  }
  &--sunset_icon {
    background-image: url("../../assets/sunset_icon.svg");
  }
  &--noon_icon {
    background-image: url("../../assets/noon_icon.svg");
  }
}
