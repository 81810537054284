.createSchedulePopup {
  width: 450px;
  padding: 25px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 16px rgb(96 96 96 / 25%);
  position: absolute;
  top: 60px;
  left: 25px;
  z-index: 999;

  &.bottomPosition {
    top: initial;
    bottom: 0;
  }

  &__block {
    margin-bottom: 24px;

    .new-button {
      margin-left: auto;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: all;
    transition: .4s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  &_name {
    font-size: 12px;
    line-height: 20px;
  }

  &_header {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  }

  .ant-picker {
    margin-bottom: 0;
  }
}
