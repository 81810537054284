.chartTasks {
  width: 283px;

  &__legend {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 16px;

    .legends__item {
      width: max-content;
      min-width: 108px;
      margin-right: 0;
    }
  }

  &__lines {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 164px;
    margin-top: 16px;
  }

  &__linesItem {
    min-height: 2px;
    flex: 1;
    border-radius: 4px 4px 0 0;
    margin-right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    transition: 1s ease-in-out;
    position: relative;

    &.grey {
      background: #B0B2B3;
    }

    &.green {
      background: #6FCF97;
    }

    &.pink {
      background: #FE8282;
    }

    &.red {
      background: #E35B5B;
    }

    &:last-of-type {
      margin-right: 0;
    }

    span {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
    }
  }
}
.chartDiagrams {
  height: 190px;

  .chartTasks__lines {
    height: 100%;
  }
}
