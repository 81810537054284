.tasks-no-time {
  position: relative;
  margin-left: -20px;

  &__wrapper {
    display: flex;
    overflow: hidden;
    overflow-x: auto;
    padding: 24px 0 40px 20px;
  }

  &__free {
    max-width: 536px;
    min-width: 536px;
    width: 100%;
    margin-right: 48px;
  }

  &__free-container {
    .task-no-time {
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__line {
    width: 1px;
    min-width: 1px;
    background: #DDDDDD;
  }

  &__in-work {
    padding-right: 48px;
    margin-left: 48px;
    width: 100%;

    .tasks-no-time-filter {
      margin-bottom: 8px;
    }

    .tasks-no-time__title {

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  &__in-work-container {
    .task-no-time-in-work {
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #333333;
    margin-bottom: 24px;

    &--two {
      margin-top: 48px;
    }
  }

  &__no-task {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A5A7A8;
  }
}
