.tasks-no-time-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  user-select: none;

  .sort-arrows {
    display: flex;
    flex-direction: column;
    margin-left: 14px;

    .stroke-current {
      stroke: #A5A7A8;
      width: 11px;
      height: 6px;
      margin-bottom: 4.5px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    padding: 0 48px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A5A7A8;
    position: relative;
    flex: 1;
    max-width: 516px;
    min-width: 444px;
  }

  &__period {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A5A7A8;
    min-width: 250px;
    max-width: 250px;
  }

  &__performer {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A5A7A8;
    min-width: 250px;
    max-width: 250px;
  }

  &__wrapper {
    display: flex;
    align-items: center;

    &.true {
      .sort-arrows {
        svg {
          &:first-of-type {
            stroke: black;
          }
        }
      }
    }

    &.false {
      .sort-arrows {
        svg {
          &:last-of-type {
            stroke: black;
          }
        }
      }
    }

    > svg {
      width: 24px;
      height: 24px;
    }
  }
}
