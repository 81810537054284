.history-page {
  width: 100%;

  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #333333;
    margin-bottom: 24px;
  }

  &__date {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 12px;

    &:not(&:first-of-type) {
      margin-top: 24px;
    }
  }
}
