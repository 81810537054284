$accent: #020850;
$black: #333;
$white: #fafafa;
$pure-white: #ffffff;
$light-grey: #c6c6c6;
$salmon: #FE8282;
$lemon: #FFCF52;
$orange: #FA9131;
$grass: #6FCF97;
$sky: #2D9CDB;
$txt200: #A5A7A8;
$bg200: #EFEFEF;
$sky-light: #9FDFFF;
$ocean200: #2F80ED;

$modifiers: (
  "warning": $lemon,
  "danger": $salmon,
  "success": $grass,
  "new": $sky,
  "default": $light-grey
);
$status: (
  "warning": $pure-white $lemon,
  "error": $pure-white $salmon,
  "success": $pure-white $grass,
  "pause": $pure-white $orange,
  "new": $pure-white $sky,
  "default": $black $light-grey,
  "template": $pure-white $sky-light,
  "template-everyday": $pure-white $sky,
  "approved": $sky #F5F9FE
);
$task_bg: (
  "progress": #FFFAEE,
  "error": #FFF3F3,
  "success": #F1FAF5,
  "default": #FAFAFA
);

// create color modifiers
@each $name, $color in $modifiers {
  .color__#{$name} {
    color: $color !important;
  }
  .bg__#{$name} {
    background-color: $color !important;
  }
}

@each $name, $color in $task_bg {
  .task_status__#{$name} {
    background-color: $color !important;
  }
}
