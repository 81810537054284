.task-message {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(196, 196, 196, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  opacity: 0;
  pointer-events: none;

  &__container {
    position: relative;
    background: white;
    min-width: 600px;
    padding: 45px;
  }

  &__close {
    position: absolute;
    right: 45px;
    top: 45px;
    cursor: pointer;
  }

  &__title {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 45px;
  }

  .textarea {
    min-height: 100px;
    max-height: 100px;
  }

  .file-input {
    display: block;
  }

  &__inner {
    display: flex;
    justify-content: flex-end;
  }

  &.open {
    opacity: 1;
    pointer-events: all;
  }
}
