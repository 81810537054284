.workshift-info {
  position: absolute;
  left: calc(100% + 6px);
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 368px;
  background-color: white;
  padding: 24px;
  text-align: left;
  border-radius: 5px;
  box-shadow: 0 0 16px rgba(96, 96, 96, 0.25);
  cursor: default;
  z-index: 999;
  opacity: 0;

  &_left {
    left: unset;
    right: calc(100% + 6px);

    &::before {
      left: unset !important;
      right: -7px;
    }

    &::after {
      left: unset !important;
      right: 0;
    }
  }

  &_top {
    top: calc(100% + 140px);
    min-height: 380px;

    &::before {
      top: 13px !important;
      transform: rotate(45deg) !important;
    }

    &::after {
      top: 1px !important;
      transform: none !important;
    }
  }

  &_bottom {
    top: calc(100% - 195px);
    min-height: 380px;

    &::before {
      top: 350px !important;
      transform: rotate(45deg) !important;
    }

    &::after {
      top: 332px !important;
      transform: none !important;
    }
  }

  &_partTimeCancel {
    .workshift-info__status {
      color: $salmon;
    }

    .workshift-info__time {
      background: #FFF3F3;
      color: #FE8282;
    }
  }

  &_warning {
    .workshift-info__status-description {
      color: $salmon;
    }

    .workshift-info__dinnerTime {
      color: $black;
    }
  }

  & > * {
    opacity: 0;
    transform: scale(0.95);
  }

  &_shown {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      left: -7px;
      width: 15px;
      height: 15px;
      background-color: white;
      border: solid white;
      border-width: 0 0 2px 2px;
      box-shadow: 0 0 16px rgba(96, 96, 96, 0.25);
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 20px;
      height: 45px;
      background-color: white;
      border: solid white;
    }

    > * {
      opacity: 1;
      transform: none;
    }
  }

  &__title {
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #333333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__status {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #333333;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  &__status-description {
    font-size: 14px;
    line-height: 20px;
    margin-top: 14px;
    color: $txt200;
  }

  &__time-block {
    display: flex;
    flex-wrap: wrap;

    button {
      margin: 0 10px 10px 0;
      white-space: nowrap;
    }
  }

  &__time {
    width: 90px;
    height: 40px;
    padding: 10px 8px;
    display: block;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #FAFAFA;
    color: #333333;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;

    &:disabled {
      cursor: default;
      color: $txt200;
    }

    &_selected {
      background: #F5F9FE;
      color: #2F80ED !important;
    }
  }

  &__action-close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 16px;
    right: 16px;
    z-index: 2;
    transition: .4s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }

    svg {
      pointer-events: none;
    }
  }

  &__actions {
    margin-left: auto;
    width: max-content;
    display: flex;
    gap: 8px;
    margin-top: 24px;
  }

  &__dinner {
    margin-top: 24px;
  }

  &__dinnerTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #A5A7A8;
  }

  &__dinnerTime {
    margin-top: 2px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2F80ED;
  }

  .shiftEditForm {
    .has-error {
      color: #e74c3c;
    }
  }
}
