.new-select {
  width: 100%;

  &__label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 4px;
  }

  &__select {
    cursor: pointer;
  }

  &__icon {
    display: flex;
    margin-right: 16px;
  }

  &__value {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__multiValue {
    white-space: nowrap;
  }

  &__check {

  }

  &__error {
    color: #FE8282;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.16px;
    margin-top: 8px;
  }

  .CLEAR {
    display: none;
  }
}
