@mixin user-avatar {
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  border-radius: 24px;
  background: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1.25;
  color: #333333;
  cursor: pointer;
  padding: 0;
  margin: 0;
  position: relative;

  &__remove {
    transition: .4s ease-in-out;
    position: absolute;
    top: -6px;
    right: -6px;
    opacity: 0;
  }

  &:hover {
    .user-avatar__remove {
      opacity: 1;
    }
  }
}

.user-menu {
  width: calc(100% - 120px);
  background-color: white;
  border-radius: 5px;
  padding: 24px 48px;
  margin: -48px -48px 0 0;
  position: absolute;
  top: 48px;
  right: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;

  & > *:not(&__needChangePassword) {
    flex-shrink: 0;
  }

  &__notification-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    margin: 0;
    position: relative;

    &_unreaded::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background: #FE8282;
      position: absolute;
      top: 2px;
      right: 2px;
    }
  }

  &__user-button {
    margin-left: 32px !important;
  }

  &__date {
    font-size: 14px;
    line-height: 18px;
    color: #B0B2B3;
  }

  &__item {
    height: 48px;
    border-right: 1px solid #EFEFEF;
    padding: 0 24px;
    display: flex;
    align-items: center;

    &--task {
      position: relative;
      margin-right: 25px;

      .task-info {
        top: 60px;
        transform: none;
        left: auto;
        right: 25px;
      }
    }
  }

  &__task {
    display: flex;
    align-items: center;
    position: relative;

    svg {
      path {
        stroke: #B0B2B3;
      }
    }

    &-status {
      margin-right: 15px;

      .label-status {
        margin: 0;
      }
    }

    &--active {
      .user-menu__time {
        &-start {
          color: #2F80ED;
        }

        &-end {
          color: #B0B2B3;
        }
      }

      &-end {
        .user-menu__time {
          &-start {
            color: #2F80ED;
          }

          &-end {
            color: #2F80ED;
          }

          &:after {
            background: #2F80ED;
          }
        }
      }
    }
  }

  &__smena {
    display: flex;
    align-items: center;
    position: relative;

    &:after {
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 100%;
      background: #B0B2B3;
    }

    svg {
      path {
        stroke: #B0B2B3;
      }
    }

    &--active {
      .user-menu__time {
        &-start {
          color: #2F80ED;
        }

        &-end {
          color: #B0B2B3;
        }
      }

      &:after {
        background: #2F80ED;
      }

      &-end {
        .user-menu__time {
          &-start {
            color: #2F80ED;
          }

          &-end {
            color: #2F80ED;
          }
        }

        &:after {
          background: #2F80ED;
        }
      }
    }
  }

  &__block-date-time {
    margin-right: 8px;
  }

  &__time {
    display: flex;
    align-items: center;

    &-start {
      color: #333333;
      margin-left: 6px;

      &_active {
        color: #2F80ED;
      }
    }

    &-end {
      color: #333333;
      margin-left: 6px;

      &_active {
        color: #B0B2B3;
      }

      &_active-end {
        color: #2F80ED;
      }
    }
  }

  &__calendar {
    display: flex;
    position: relative;

    &--active {
      &::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: #FE8282;
        position: absolute;
        top: 3px;
        right: 3px;
      }
    }
  }

  &__item--calendar {
    margin-right: 24px;
  }

  &__dinner {
    flex-grow: 1;

    .dinner {
      padding: 8px;
      display: flex;
      align-items: center;
      width: max-content;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #2F80ED;
      background: #EBF3FE;
      border-radius: 4px;

      svg {
        margin-right: 8px;
      }
    }
  }

  &__needChangePassword {
    flex-grow: 1;
    justify-content: flex-start;
    color: #FE8282;

    .needChangePassword {
      &__content {
        display: flex;
        justify-content: center;
        align-content: center;
        width: max-content;
        font-size: 16px;
        line-height: 24px;
        background: #FFF3F3;
        border-radius: 5px;
        padding: 10px;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }

        span {
          margin-left: 5px;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
    }
  }
}

.user-avatar {
  @include user-avatar;
}
