.history {
  border: 1px solid #EBEBEB;
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }

  .content {
    padding: 24px;
  }

  .more {
    display: flex;
    padding: 25px;
    align-items: center;
    justify-content: space-between;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #2F80ED;

    svg {
      path {
        stroke: #2F80ED;
      }
    }
  }

  .delimeter {
    height: 1px;
    background-color: rgba(51, 51, 51, 0.1);
    width: 100%;
  }
}

.history-entry {
  display: flex;
  flex-direction: row;

  &:not(:first-child) {
    margin-top: 8px;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }

  .description {
    overflow: hidden;

    .grey {
      color: $txt200;

      &.zone {
        svg {
          transform: translate(0, 5px);
          margin-left: -4px;
        }
      }

      &.comment {
        svg {
          transform: translate(0, 6px) scale(.7);
          margin-left: -4px;
        }
      }
    }

    .images {
      display: flex;
      padding-top: 5px;
      gap: 8px;
    }
  }

  .time {
    margin-right: 18px;
  }
}

.history-page {
  width: 100%;

  h3 {
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 24px;
  }
}
