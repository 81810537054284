.login {
  width: 100vw;
  display: flex;
  align-items: stretch;

  &__illustration,
  &__form {
    width: 50vw
  }

  &__form {
    padding-left: 173px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h1 {
      margin-bottom: 48px;
    }

    .new-input {
      margin-bottom: 24px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .new-button {
      margin-top: 48px;
    }

    .error-message {
      margin-top: 5px;
      font-size: 16px;
    }

    .new-input {
      max-width: 320px;
    }
  }

  &__illustration {
    background: #F5F9FE;

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__error {
    background: #FFF3F3;
    border-radius: 5px;
    width: 100%;
    max-width: 320px;
    padding: 10px;
    color: #FE8282;
    margin-top: 48px;
    font-size: 14px;
    line-height: 20px;
  }

  &__copyright {
    position: absolute;
    bottom: 15px;
    left: 17px;
    font-size: 90%;
  }
}
