.files {

  &__wrapper {
    gap: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .file-input {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px dashed #2F80ED;

    .new-button {
      pointer-events: none;
    }

    &__image {
      display: block;
    }

    &__title {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #2F80ED;
    }

    &__description {
      margin-top: 4px;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.16px;
      color: #a5a7a8;
      width: 100%;
      font-weight: 400;
    }

    &__input {
      display: none;
    }
  }

}
.ant-image-preview-mask {
  z-index: 10000;
}
.ant-image-preview-wrap {
  z-index: 10080;
}
