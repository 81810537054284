.dialogPopup {
  padding: 0px !important;
  width: 512px;
  &_container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 16px;
  }

  &_header {
    font-size: 24px;
    line-height: 28px;
  }

  &_description {
    font-size: 16px;
    line-height: 18.75px;
  }
  &_buttons {
    padding: 12px 24px;
    display: flex;
    justify-content: end;
    gap: 24px;
  }

  .popupClose{
    top: 16px;
    right: 16px;
  }

}
