.alert {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease;
  z-index: 199999;
  pointer-events: none;
  background: rgba(196, 196, 196, 0.3);

  &__active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &-dialog {
    z-index: 1;
    width: 512px;
    display: flex;
    flex-direction: column;
    background: $pure-white;
    box-shadow: 0 0 16px rgba(96, 96, 96, 0.08);
    border-radius: 5px;
    pointer-events: all;

    &__close {
      display: flex;
      position: absolute;
      top: 16px;
      right: 16px;
    }

    &__content {
      position: relative;
      flex: 1;
      padding: 24px;
    }

    &__header {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: $black;
      }
    }

    &__text {
      margin-top: 16px;
      margin-bottom: 30px;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__buttons {
      padding: 20px 24px;
      border-top: 1px solid rgba(51, 51, 51, 0.1);
      justify-content: flex-end;
      align-items: center;
      display: flex;

      button {
        &:not(:last-child) {
          margin-right: 40px;
        }

        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #2F80ED;

        &.delete {
          color: #FE8282;
        }
      }
    }
  }
}
