@mixin aside-popup__title {
  color: #333333;
  font-size: 24px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin-bottom: 3px;
  padding: 0;
  border-radius: 0;
  background: transparent;
}

.aside-popup {
  position: fixed;
  top: 0;
  right: -612px;
  width: 612px;
  height: 100vh;
  max-height: 100vh;
  background: #ffffff;
  z-index: 9000;
  display: flex;
  flex-direction: column;
  padding: 45px;
  transition: 0.5s ease;

  &_active {
    right: 0;
  }

  &.view-task {
    &--users {
      .aside-popup__body {
        padding-bottom: 340px;
      }
    }
  }

  &__title {
    @include aside-popup__title;

    &_input {
      padding-bottom: 9px;
      border-bottom: 1px solid #333;
      margin-bottom: 45px;
    }
  }

  &__header {
    padding-bottom: 48px;
    transition: 0.3s ease;

    &-button {
      display: flex;
      align-items: center;
      cursor: pointer;
      pointer-events: all;

      &_log-out {
        color: #2F80ED;
        font-size: 20px;
        line-height: 24px;

        svg {
          display: block;
          margin-left: 8px;
          margin-top: 2px;
          transition: .3s ease-in-out;
        }

        &:hover {
          svg {
            transform: translateX(3px);
          }
        }
      }

      &_close {
        margin-left: auto;
      }
    }

    &_fixed {
      padding: 35px 43px;
      background: #ffffff;
      box-shadow: 0 4px 16px rgba(96, 96, 96, 0.08);

      .aside-popup__title {
        margin: 0 !important;
      }
    }

    &--task {
      width: 100%;
    }

    &--disable {
      .popover {
        display: none;
      }
    }
  }

  &__body {
    width: calc(100% + 90px);
    padding: 0 45px 70px;
    position: relative;
    left: -45px;
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .input-block__title {
      font-size: 16px;
    }

    .task-carousel__plus-circle {
      margin: 0;
    }

    &--disable {
      form {
        .aside-popup__button {
          display: none;
        }

        .input-block {
          pointer-events: none;
        }

        .d-flex {
          pointer-events: none;
        }

        .textarea-wrapper {
          pointer-events: none;
        }

        .file-input {
          pointer-events: none;
          display: none;
        }

        .d-flex {
          pointer-events: none;
        }

        .add-task__users-block {
          pointer-events: none;
        }

        .task-carousel__plus-circle {
          display: none;
        }

        .checkbox {
          pointer-events: none;
        }
      }
    }
  }

  &__button {
    margin-top: auto;
    align-self: flex-end;
    margin-bottom: 0;
  }

  .subtitle {
    font-size: 16px;
    line-height: 20px;
    color: $black;
  }
}

.user-profile {

  &__block {
    margin-bottom: 24px;

    .input-block {
      margin: 0;
    }

    .new-button {
      margin-top: 24px;
      margin-left: auto;
    }

    .ant-picker {
      margin: 0;
    }

    .workerPopupGeneratePassword {
      margin-top: 30px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__info {
    display: flex;
    cursor: default;
    margin-bottom: 48px;

    .user-avatar {
      margin-bottom: 0;
      margin-right: 24px;
    }
  }

  &__name {
    padding-right: 10px;
    @include aside-popup__title;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 28px;
  }

  &__position {
    color: #a5a7a8;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }

  &__btn-save {
    margin-top: 24px;
  }

  &__btn-change {
    margin-top: 62px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #2F80ED;
  }

  .shifts {
    .shift-day {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #2F80ED;
      }

      .buttons {
        display: flex;

        button {
          border: 1px solid transparent;
          border-radius: 5px;
          text-align: center;
          padding: 0 5px;
          width: 80px;
          height: 40px;
          letter-spacing: 0.16px;
          background: #FAFAFA;
          color: #A5A7A8;
          transition: 0.3s ease;
          font-size: 14px;
          line-height: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &:not(:last-child) {
            margin-right: 10px;
          }

          &.selected {
            background: #F5F9FE;
            color: #2F80ED;
          }

          &:disabled {
            color: #B0B2B3;
            cursor: default;
          }
        }
      }
    }
  }

  .date-start {
    margin-bottom: 33px;

    .values {
      display: flex;

      .input-block {
        margin-bottom: 0;
        width: 80px;
        height: 40px;

        &:not(:last-child) {
          margin-right: 10px;
        }

        .input {
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          text-align-last: center;
        }
      }
    }
  }
}

.time-inputs {

  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
    width: 100%;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: #fafafa;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: #A5A7A8;
    margin-right: 10px;
    width: auto;
    height: auto;
    margin-bottom: 0;

    &_input {
      width: 90px;
    }
  }

  &__input {
    padding: 0;
    margin: 0;
    background: transparent;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    display: inline-block;
    color: #A5A7A8;
    text-align: right;
    width: 1.1em;
  }
}

.textarea-wrapper {
  transition: all 200ms;
  opacity: 1;
  visibility: visible;

  &.with-animation {
    height: 100%;
  }

  &.hidden {
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    height: 0;
  }
}

.add-task {

  &__users {
    display: flex;
    flex-direction: column;
    position: relative;

    &-block {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;
    }

    p {
      font-size: 12px;
      line-height: 20px;
    }

    &-remove {
      font-size: 12px;
      line-height: 20px;
      text-decoration: underline;
      color: $txt200;
      margin-left: 5px;
    }

    &-lock {
      opacity: 0;
      pointer-events: none;
      transition: .4s ease-in-out;
      background: rgba(255, 255, 255, 0.62);
      backdrop-filter: blur(2px);
      padding: 0 0 0 25px;
      display: flex;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      &-icon {
        display: flex;
        margin-right: 11px;
      }

      &-text {
        display: flex;
        max-width: 178px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #343940;
      }
    }

    &--disable {
      .add-task__users-block {
        pointer-events: none;
      }

      &:hover {
        .add-task__users-lock {
          opacity: 1;
        }
      }
    }
  }
}

.workerPopupGeneratePassword {
  color: #2F80ED;
  cursor: pointer;
}
