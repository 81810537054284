.generatePasswordPopup {
  padding: 24px !important;
  max-width: 512px;
  width: 100%;

  .popupClose {
    top: 16px !important;
    right: 16px !important;
  }
}

.popupWrapper {

  .passwordPopupHeader {
    font-size: 24px;
  }

  .passwordPopupDesc {
    font-size: 16px;
    margin: 16px 0 24px;
    color: #FE8282;
  }

  .generatedPassword {
    margin-bottom: 24px;

    .new-button {
      &__text {
        font-size: 20px !important;
        line-height: 24px !important;
      }
    }
  }

  .buttonsLine {
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #EFEFEF;

    & > div {
      margin-left: 40px;
    }

    .disabled {
      pointer-events: none;
      color: $txt200;
    }
  }
}
