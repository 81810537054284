.task-title {
  position: relative;

  .task-list {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
}
